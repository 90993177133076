import React from "react";
import { useHistory } from "react-router-dom";

import Bg from "../assets/images/bg-error.png";
import Logo from "../assets/images/logo-white.png";
import Button from "../components/inputs/Button";

const NotFound = () => {
  const history = useHistory();

  return (
    <div
      style={{ backgroundColor: "#061031" }}
      className="w-full h-screen relative overflow-auto"
    >
      <img
        src={Bg}
        alt="Error Background"
        className="z-0 fixed inset-0 w-full h-full object-cover object-top"
      />

      <div className="py-20 px-10 sm:px-20 sm:py-32 text-white relative">
        <img
          src={Logo}
          alt="Phoenix Logo"
          width={288}
          className="w-28 h-auto mb-20"
        />

        <h1 className="text-7xl sm:text-9xl font-black tracking-widest mb-5">
          404
        </h1>
        <p className="text-2xl mb-10">
          Oops! Please turn back,
          <br />
          the page you are looking for could not be found.
        </p>
        <Button
          fontSize="text-base"
          paddingX="px-12"
          paddingY="py-3"
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
