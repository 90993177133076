import React, { useState } from "react";
import Modal from "react-responsive-modal";

import Button from "../../inputs/Button";
import UpdateContactInfo from "./UpdateContactInfo";

import Avatar from "../../../assets/images/avatar.png";

const UserProfile = ({ data = {} }) => {
  const [showContactEdit, setShowContactEdit] = useState(false);
  const [changeProfilePic, setChangeProfilePic] = useState(false);

  return (
    <>
      <div className="w-full">
        <div className="w-full">
          <h2 className="text-xl text-center font-bold mb-5">Edit Profile</h2>

          <div className="w-full text-center mb-4">
            <div className="w-15 h-15 inline-block rounded-full p-3 border">
              <img
                src={Avatar}
                className="w-full h-full rounded-full"
                alt="Avatar"
              />
            </div>
            <input
              type="file"
              className={`place-content-center ${
                changeProfilePic ? "cursor-pointer inline-block" : "hidden"
              }`}
            />

            <div
              className=""
              onClick={(e) => setChangeProfilePic(!changeProfilePic)}
              role="presentation"
            >
              Tap to change picture
            </div>
          </div>

          <div className="w-full">
            <div className="w-full flex justify-between items-center p-2">
              <p className="">First Name</p>
              <h3 className="text-xl font-bold">{data?.f_name}</h3>
            </div>

            <div className="w-full flex justify-between items-center p-2">
              <p className="">Last Name</p>
              <h3 className="text-xl font-bold">{data?.l_name}</h3>
            </div>

            <div className="w-full flex justify-between items-center p-2">
              <p className="">Email Address</p>
              <h3 className="text-xl font-bold">{data?.email}</h3>
            </div>

            <div className="w-full flex justify-between items-center p-2">
              <p className="">Phone Number</p>
              <h3 className="text-xl font-bold">{data?.phone}</h3>
            </div>
          </div>
        </div>

        <p className="mt-3 mb-5 text-center">
          If you have issues with your information, kindly send a message to
          help@loanapp.com
        </p>

        <div className="w-full">
          <h2 className="text-lg font-bold mb-2 uppercase">
            Contact Information
          </h2>

          <div className="w-full">
            <div className="w-full flex justify-between items-center p-2">
              <p className="">Address</p>
              <h3 className="text-xl font-bold">{data?.address}</h3>
            </div>

            <div className="w-full flex justify-between items-center p-2">
              <p className="">City</p>
              <h3 className="text-xl font-bold">{data?.city}</h3>
            </div>

            <div className="w-full flex justify-between items-center p-2">
              <p className="">State</p>
              <h3 className="text-xl font-bold">{data?.state}</h3>
            </div>

            <Button
              onClick={() => setShowContactEdit(true)}
              className="w-full mt-5"
              paddingY="py-3"
            >
              Edit Contact Info
            </Button>
          </div>
        </div>
      </div>

      <Modal
        center
        open={showContactEdit}
        closeOnOverlayClick={false}
        onClose={() => setShowContactEdit(false)}
        classNames={{ modal: "rounded-md no-padding" }}
      >
        <div className="w-72 sm:w-96 mb-5">
          <UpdateContactInfo
            data={data}
            closeModal={() => setShowContactEdit(false)}
          />
        </div>
      </Modal>
    </>
  );
};

export default UserProfile;
