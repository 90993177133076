import React, { useEffect } from "react";

import AspirationsContainer from "../../components/containers/goals/Aspirations";
import ViewWrapper from "../../layouts/ViewWrapper";

const Aspirations = () => {
  useEffect(() => {}, []);

  return (
    <ViewWrapper header="Aspirations">
      <AspirationsContainer />
    </ViewWrapper>
  );
};

export default Aspirations;
