import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-responsive-modal";

import { RiDeleteBin2Fill } from "react-icons/ri";

import CartContext from "../../../contexts/CartContext";

import Button from "../../inputs/Button";
import CartButton from "./CartButton";
import { formatCurrency } from "../../../utilities/general";
import MakeInvestment from "./MakeInvestment";

const Cart = () => {
  const history = useHistory();
  const { cart, clearCart, removeItem } = useContext(CartContext);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {}, []);

  const total = () => {
    if (cart?.length < 1) return 0;
    return cart?.reduce((prev, cur) => ({
      amount: parseInt(prev?.amount, 10) + parseInt(cur?.amount, 10),
    }));
  };

  return (
    <>
      <div className="w-full sm:w-2/3 xl:w-1/2 mx-auto">
        <div className="w-full flex justify-between items-center mb-5">
          <Button className="" onClick={() => history.goBack()}>
            Back
          </Button>
          <CartButton />
        </div>

        <div className="w-full">
          {cart?.map((item) => (
            <div
              className="w-full flex justify-between items-center py-4 px-1 border-b"
              key={item?.id}
            >
              <div className="flex items-center">
                <span
                  className={`${
                    item?.type === "primary" ? "bg-yellow-500" : "bg-primary"
                  } mr-3 font-bold text-3xl text-white w-14 h-14 rounded-full flex justify-center p-3`}
                >
                  {item?.type === "primary" ? "P" : "S"}
                </span>
                <div className="">
                  <h3 className="text-lg font-bold">{item?.title}</h3>
                  <p className="text-base text-gray-300">
                    {item?.tenure}
                    &nbsp;month(s) maturity
                  </p>
                </div>
              </div>

              <div className="flex items-center">
                <div className="text-right">
                  <p className="text-base font-bold">
                    {formatCurrency(item?.amount)}
                  </p>
                  <p className="text-base text-success">{`${item?.interest_rate}%`}</p>
                </div>
                <RiDeleteBin2Fill
                  className="ml-5 bg-primary text-white p-1 w-8 h-auto rounded cursor-pointer bg-opacity-70 hover:bg-opacity-100"
                  onClick={() => removeItem(item?.id)}
                />
              </div>
            </div>
          ))}

          {cart?.length <= 0 && <center className="py-3">No Item</center>}
        </div>

        <div className="w-full flex justify-between items-center mt-2">
          <p className="text-lg font-bold">Total Investment:</p>
          <p className="text-lg font-bold">{formatCurrency(total()?.amount)}</p>
        </div>

        <div className="flex flex-wrap -mx-3 mt-10">
          <div className="px-3 w-1/2">
            <Button
              onClick={() => setShowModal(true)}
              paddingY="py-2.5"
              className="w-full"
              disabled={cart?.length < 1}
            >
              Buy Token
            </Button>
          </div>
          <div className="px-3 w-1/2">
            <Button
              onClick={() => {
                clearCart();
              }}
              paddingY="py-2.5"
              bgColor="bg-white"
              textColor="text-primary"
              border
              className="w-full"
            >
              Clear
            </Button>
          </div>
        </div>
      </div>

      <Modal
        center
        open={showModal}
        closeOnOverlayClick={false}
        onClose={() => setShowModal(false)}
        classNames={{ modal: "rounded-md no-padding" }}
      >
        <div className="w-72 sm:w-96 mb-5">
          <MakeInvestment
            data={cart}
            total={total()?.amount}
            closeModal={() => setShowModal(false)}
          />
        </div>
      </Modal>
    </>
  );
};

export default Cart;
