import React, { useEffect, useState } from "react";

import Modal from "react-responsive-modal";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import notification from "../../../utilities/notification";
import handleFetch from "../../../api/handleFetch";
import { formatCurrency } from "../../../utilities/general";
import Button from "../../inputs/Button";
import SetGoals from "./SetGoals";

const GoalCard = ({ data }) => {
  const [user, setUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const u = JSON.parse(localStorage.getItem("u"));
    setUser(u);
    const getCategories = async () => {
      // setIsLoading(true);
      await handleFetch({ endpoint: "/investment-goal-categories" })
        .then((res) => {
          // setCategoryList(res);
        })
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
      // setIsLoading(false);
    };
    getCategories();
  }, []);

  const percent =
    (user?.total_invested_amount / (user?.investment_goal?.amount || 1)) * 100;

  return (
    <>
      <div
        // style={{ backgroundColor: "#CEE6DB" }}
        className="w-full mb-4 bg-[#CEE6DB] dark:bg-secondary rounded px-5 py-8"
      >
        {user?.investment_goal?.amount ? (
          <div className="w-full flex justify-between items-center">
            <div className="w-max mr-5">
              <h4 style={{ color: "#BEA74A" }} className="font-bold mb-2">
                Investment Goals
              </h4>
              <h3 className="font-bold text-xl mb-3 dark:text-white">
                {formatCurrency(user?.total_invested_amount)}
                &nbsp;of&nbsp;
                <span className="text-[#5D8371] dark:text-[#8CD867]">
                  {formatCurrency(user?.investment_goal?.amount)}
                </span>
              </h3>

              <Button
                onClick={() => setShowModal(true)}
                bgColor="bg-white"
                textColor="text-primary"
              >
                Edit Goals
              </Button>
            </div>

            <div className="w-max">
              <div style={{ width: 100, height: 100 }}>
                <CircularProgressbar
                  value={percent}
                  text={`${
                    parseFloat(percent).toFixed(2) > 100
                      ? 100
                      : `${parseFloat(percent).toFixed(2)}`
                  }%`}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            <h4 className="text-[#BEA74A] dark:text-white font-bold mb-2">
              Your Investment Goal
            </h4>
            <h3 className="font-bold dark:text-white text-xl mb-3">
              You have not set any goal yet.
            </h3>
          </div>
        )}
      </div>
      <SetGoals data={user?.investment_goal} />
      <Modal
        center
        open={showModal}
        closeOnOverlayClick={false}
        onClose={() => setShowModal(false)}
        classNames={{ modal: "rounded-md no-padding" }}
      >
        <div className="w-72 sm:w-96">
          <SetGoals data={user?.investment_goal} />
        </div>
      </Modal>
    </>
  );
};

export default GoalCard;
