import React, { useState } from "react";

import Button from "../../inputs/Button";
import TextInput from "../../inputs/TextInput";
import Tick from "../../../assets/images/success.png";

import Loading from "../../common/Loading";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";

const UpdateContactInfo = ({ data, closeModal }) => {
  const [form, setForm] = useState(1);
  const [address, setAddress] = useState(data?.address || "");
  const [city, setCity] = useState(data?.city || "");
  const [state, setState] = useState(data?.state || "");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    const body = { address, city, state };

    await handleFetch({
      endpoint: "/user/editprofile",
      method: "POST",
      body,
    })
      .then((res) => {
        setForm(2);
        localStorage.setItem(
          "u",
          JSON.stringify({
            ...data,
            address,
            city,
            state,
          })
        );
      })
      .catch((err) => {
        notification({
          title: "Error",
          message: err?.toString(),
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}

      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl font-bold mb-5">Update Contact Info</h2>

          <div className="w-full">
            <div className="mb-10">
              <TextInput
                value={address}
                onChange={(e) => setAddress(e?.target?.value)}
                className="w-full mb-5"
                label="Address"
              />
              <TextInput
                value={city}
                onChange={(e) => setCity(e?.target?.value)}
                className="w-full mb-5"
                label="City"
              />
              <TextInput
                value={state}
                onChange={(e) => setState(e?.target?.value)}
                className="w-full mb-5"
                label="State"
              />
            </div>

            <Button
              className="w-full mt-10"
              onClick={handleSubmit}
              paddingY="py-3"
              disabled={isLoading || !(address && city && state)}
            >
              Submit
            </Button>
          </div>
        </div>
      )}

      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500">
                You have successfully updated your contact information
              </p>
            </div>

            <Button
              onClick={() => window.location.reload()}
              className="w-full mt-5"
              paddingY="py-3"
            >
              Okay
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default UpdateContactInfo;
