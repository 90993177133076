import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { RiDeleteBin2Fill } from "react-icons/ri";

import Button from "../../inputs/Button";

import CartIcon from "../../../assets/svg/cart.svg";
import CartContext from "../../../contexts/CartContext";
import { formatCurrency } from "../../../utilities/general";
import useClickOutsideBox from "../../../hooks/useClickOutsideBox";

const CartButton = () => {
  const history = useHistory();
  const { cart, clearCart, removeItem } = useContext(CartContext);
  const [showList, setShowList] = useState(false);
  const wrapperRef = useRef(null);

  useClickOutsideBox(wrapperRef, () => setShowList(false));

  return (
    <div
      ref={wrapperRef}
      className="relative"
      role="presentation"
      onClick={() => setShowList(!showList)}
    >
      <div className="cursor-pointer">
        <img src={CartIcon} width="30" height="30" alt="Cart" className="" />
        {cart?.length > 0 && (
          <span className="absolute bg-error rounded-full text-white text-xs px-1.5 pt-1 pb-0.5 -top-1 -right-2">
            {cart?.length}
          </span>
        )}
      </div>
      {showList && (
        <div className="w-80 bg-white absolute z-10 right-0 top-full border-4 border-gray-200">
          <div className="w-full p-3">
            <div className="flex justify-end">
              <MdClose
                className="text-2xl cursor-pointer hover:bg-gray-200 transition ease-in-out"
                onClick={(e) => {
                  e && e.preventDefault();
                  e && e.stopPropagation();
                  setShowList(false);
                }}
              />
            </div>
            <div className="w-full mt-2">
              <div className="border-b pb-3 border-gray-200">
                {cart?.map((item) => (
                  <div
                    className="flex justify-between items-center py-2"
                    key={item?.id}
                  >
                    <div className="flex items-center">
                      <span
                        className={`${
                          item?.type === "primary"
                            ? "bg-yellow-500"
                            : "bg-primary"
                        } mr-3 font-bold text-xl text-white w-9 h-9 rounded-full flex justify-center p-1.5`}
                      >
                        {item?.type === "primary" ? "P" : "S"}
                      </span>
                      <div className="">
                        <h4 className="font-semibold">{item?.title}</h4>
                        <p>{formatCurrency(item?.amount)}</p>
                      </div>
                    </div>
                    <div className="">
                      <RiDeleteBin2Fill
                        className="bg-primary text-white ml-5 p-1 w-6 h-auto cursor-pointer bg-opacity-70 hover:bg-opacity-100"
                        onClick={() => {
                          removeItem(item?.id);
                          setShowList(true);
                        }}
                      />
                    </div>
                  </div>
                ))}

                {cart?.length <= 0 && <center className="py-3">No Item</center>}
              </div>
              <div className="flex flex-wrap -mx-3 mt-3">
                <div className="px-3 w-1/2">
                  <Button
                    onClick={() => history.push("/home/cart")}
                    paddingY="py-2.5"
                    className="w-full"
                  >
                    Checkout
                  </Button>
                </div>
                <div className="px-3 w-1/2">
                  <Button
                    onClick={() => {
                      clearCart();
                      setShowList(false);
                    }}
                    paddingY="py-2.5"
                    bgColor="bg-white"
                    textColor="text-primary"
                    border
                    className="w-full"
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartButton;
