import React, { useEffect, useState } from "react";
import Select from "react-select";
import handleFetch from "../../../api/handleFetch";
import TextInput from "../../inputs/TextInput";
import Button from "../../inputs/Button";
import Loading from "../../common/Loading";
import notification from "../../../utilities/notification";

const AddBank = () => {
  const [data, setData] = useState([]);
  const [form, setForm] = useState(1);
  const [accNo, setAccNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "red" : "blue",
      padding: 20,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 200,
      height: 100,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  useEffect(() => {
    const getData = async () => {
      await handleFetch({ endpoint: "/wallet/banks" })
        .then((res) => {
          const date = res.data.map((item) => ({
            value: item.name,
            id: item.id,
            label: item.name,
          }));
          setData(date);
        })
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
    };
    getData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await handleFetch({
      endpoint: "/user/wallet/bank-accounts",
      body: {
        account_number: accNo,
        bank_id: Number(selectedOption.id),
      },
      method: "POST"
    })
      .then((res) => {
        if (res.status) {
          notification({
            title: "Success",
            message: `${res?.msg.toString()}, you bank has been added`,
            type: "success",
          });
        }
        setForm(2);
      })
      .catch((err) => {
        notification({
          title: "Error",
          message: err?.toString(),
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <div>
      <>
        {isLoading && <Loading />}
        {form === 1 && (
          <div className="w-full">
            <h2 className="text-xl dark:text-white font-bold mb-5">Add your bank account</h2>
            <div className="w-full">
              <div className="mb-10">
                <Select
                  style={customStyles}
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={data}
                />
              </div>
              <div className="mb-10">
                <TextInput
                  value={accNo}
                  onChange={(e) => setAccNo(e.target.value)}
                  className="w-full mb-5"
                  label="Account Number"
                />
              </div>

              <Button
                onClick={handleSubmit}
                className="w-full mt-10"
                disabled={accNo.length < 1}
                paddingY="py-3"
              >
                Submit
              </Button>
            </div>
          </div>
        )}

        {form === 2 && (
          <div className="w-full">
            <center className="w-full px-10 mt-5">
              <div className="mb-5">
                {/* <img src={Tick} className="w-24 h-24 mb-5" alt="Success" /> */}
                <p className="text-gray-500">
                  You have successfully added your bank
                </p>
              </div>

              <Button
                onClick={() => window.location.reload()}
                className="w-full mt-5"
                paddingY="py-3"
              >
                Okay
              </Button>
            </center>
          </div>
        )}
      </>
    </div>
  );
};

export default AddBank;
