/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import ThemeContext from "../../../contexts/ThemeContext";
import TextInput from "../../inputs/TextInput";
import Button from "../../inputs/Button";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Loading from "../../common/Loading";
import SelectInput from "../../inputs/SelectInput";

// const { theme } = localStorage;
const SignupForm = ({ logout }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  // const [mode, setMode] = useState(theme === "dark");
  const { mode, changeTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (localStorage.getItem("err")) {
      notification({
        title: "Error",
        message: localStorage.getItem("err"),
        type: "danger",
      });
      localStorage.clear();
    }
    // if (localStorage.theme !== undefined && localStorage.theme === "light") {
    //   setMode(false);
    // } else {
    //   setMode(true);
    // }
    // document.documentElement.classList.toggle("dark");
    // localStorage.theme = localStorage.theme === "dark" ? "light" : "dark";

    // if (mode) {
    //   localStorage.setItem("theme", "dark");
    //   document.documentElement.classList.add("dark");
    // } else {
    //   localStorage.setItem("theme", "light");
    //   document.documentElement.classList.remove("dark");
    // }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    if (name === "phone" && (!value?.match(/^\d*$/) || value.length > 11)) {
      return;
    }

    setForm((state) => ({ ...state, [name]: value }));
  };

  const inputRange = [
    "100,000 - 500,000",
    "200,000 - 1,000,000",
    "300, 000 - 1,500,000",
    "400, 000 - 2,000,000",
  ];

  const validateForm = () => {
    if (!form?.f_name) return "First name is required";
    if (!form?.l_name) return "Last name is required";
    if (!form?.phone) return "Phone number is required";
    if (form?.phone?.length < 10) return "Please, enter a valid phone number";
    if (!form?.email) return "Email address is required";
    if (!form?.home_address) return "Home address is required";
    if (
      !/^([a-zA-Z0-9_\-&.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,10})$/.test(
        form?.email
      )
    ) {
      return "Please enter a valid email";
    }
    if (!form?.password) return "Password is required";
    if (form?.password?.length < 8) {
      return "Password must contain 8 or more characters";
    }
    if (form?.password !== form?.password_confirmation) {
      return "Password mismatch";
    }
    if (form?.income_range) {
      return "Income range required";
    }
    return false;
  };

  const handleSignup = async () => {
    const error = validateForm();

    if (error) {
      notification({
        title: "Invalid Form Entry",
        message: error,
        type: "danger",
      });
      return;
    }
    setIsLoading(true);

    await handleFetch({
      endpoint: "register",
      method: "POST",
      body: form,
    })
      .then((res) => {
        localStorage.setItem("t", res?.data?.token);
        localStorage.setItem("u", JSON.stringify(res?.data?.user));
        setTimeout(() => {
          notification({
            message: `Welcome back ${res?.data?.user?.f_name}`,
            type: "success",
          });
          history.push("/wallet");
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        notification({
          title: "Error",
          message: err?.toString(),
          type: "danger",
        });
      });
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="w-full h-full min-h-screen dark:bg-black flex">
        <div
          role="presentation"
          className="absolute right-3 top-3 cursor-pointer"
          onClick={() => {
            // setMode(!mode);
            changeTheme();
            // document.documentElement.classList.toggle("dark");
          }}
        >
          {mode ? (
            <BsFillSunFill className="text-3xl text-yellow" />
          ) : (
            <BsFillMoonStarsFill className="text-2xl text-blue-800" />
          )}
        </div>
        <div className="w-full max-w-xl m-auto p-10">
          <div className="text-gray-500">
            <h2 className="font-bold dark:text-white text-2xl mb-5">
              Let&apos;s get you started!
            </h2>
            <p className="text-base dark:text-white">
              Hey there, let&apos;s set up your Phoenix account. It&apos;s
              simple.
            </p>
            <div className="w-full mt-7">
              <TextInput
                label="First Name"
                value={form?.f_name || ""}
                name="f_name"
                onChange={handleChange}
                className="w-full mb-5 dark:text-white"
                placeholder="First Name"
              />
              <TextInput
                label="Last Name"
                value={form?.l_name || ""}
                name="l_name"
                onChange={handleChange}
                placeholder="Last Name / Surname"
                className="w-full mb-5 dark:text-white"
              />
              <TextInput
                label="Email"
                value={form?.email || ""}
                name="email"
                onChange={handleChange}
                className="w-full mb-5 dark:text-white"
                placeholder="example@mail.com"
              />
              <SelectInput
                label="Income Range"
                value={form?.income_range || ""}
                name="income_range"
                options={inputRange}
                onChange={handleChange}
                className="w-full mb-5 dark:text-white"
                placeholder="1000000 - 2000000"
              />
              <TextInput
                label="Home Address"
                value={form?.home_address || ""}
                name="home_address"
                onChange={handleChange}
                className="w-full mb-5 dark:text-white"
                placeholder="7, adele street"
              />
              <>
                <label htmlFor={form?.phone} className="dark:text-white">
                  Phone Number
                </label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  country="ng"
                  specialLabel="Phone Number"
                  inputStyle={{
                    height: "54px",
                    width: "100%",
                    boxShadow:
                      " 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                    borderColor: "rgba(209, 213, 219,1)",
                    marginBottom: "20px",
                  }}
                  value={form?.phone}
                  className="mb-3"
                  onChange={(phone) =>
                    setForm({
                      ...form,
                      phone,
                    })
                  }
                />
              </>
              <TextInput
                label="Password"
                value={form?.password || ""}
                name="password"
                onChange={handleChange}
                type="password"
                className="w-full mb-5 dark:text-white"
                placeholder="Minimum 8 characters"
              />
              <TextInput
                label="Confirm Password"
                value={form?.password_confirmation || ""}
                name="password_confirmation"
                onChange={handleChange}
                type="password"
                className="w-full mb-5 dark:text-white"
                placeholder="Minimum 8 characters"
              />

              <div className="inline-flex dark:text-white">
                <input
                  type="checkbox"
                  name=""
                  className="mr-2 mt-1 mb-10 self-start cursor-pointer"
                  checked={isCheck}
                  onChange={(e) => setIsCheck(!isCheck)}
                  id=""
                />
                <p>
                  By continuing, you agree to our{" "}
                  <span>
                    <a
                      className="underline"
                      href="https://moneyworx.ganbaru.xyz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms and Conditions of Service and Privacy Policy
                    </a>
                  </span>
                </p>
              </div>

              <Button
                className="w-full dark:text-black dark:bg-yellow"
                paddingY="py-4"
                onClick={handleSignup}
              >
                Register
              </Button>

              <div className="flex justify-between mt-3 mb-10">
                <Link
                  className="hover:text-primary dark:text-white"
                  to="/login"
                >
                  Login
                </Link>
                <Link
                  className="hover:text-primary dark:text-red-600"
                  to="/forget-password"
                >
                  Forget Password?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupForm;
