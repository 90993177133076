import React, { useEffect, useState } from "react";

import { CgArrowsExchangeAltV } from "react-icons/cg";

import Button from "../../inputs/Button";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Loading from "../../common/Loading";
import { formatDateTime } from "../../../utilities/dateTime";

const Transactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await handleFetch({ endpoint: "/user/wallet/transactions" })
        .then((res) => setData(res?.data))
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
      setIsLoading(false);
    };

    getData();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <div className="w-full md:w-2/3 xl:w-1/2 mx-auto">
        <div className="w-full mb-5">
          {data?.data?.map((item) => (
            <div
              key={item?.id}
              className="w-full flex justify-between items-center py-4 border-b last:border-0"
            >
              <div className="flex items-center">
                {item?.type === "deposit" ? (
                  <CgArrowsExchangeAltV className="bg-success bg-opacity-10 text-success w-12 p-2 mr-3 h-auto rounded-full" />
                ) : (
                  <CgArrowsExchangeAltV className="bg-error bg-opacity-10 text-error w-12 p-2 mr-3 h-auto rounded-full" />
                )}
                <div className="">
                  <h3 className="text-lg dark:text-white font-bold">
                    {item?.meta?.desc.charAt(0).toUpperCase() +
                      item?.meta?.desc.slice(1)}
                  </h3>
                  <p className="text-base text-gray-300">
                    {formatDateTime(item?.created_at)}
                  </p>
                </div>
              </div>

              <div className="">
                <p
                  className={`text-xl font-bold w-max ${
                    item?.type === "deposit" ? "text-success" : "text-error"
                  }`}
                >
                  {`${item?.type === "deposit" ? "+" : ""}${item?.amount}`}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="w-full">
          <Button
            className="w-full"
            bgColor="bg-white"
            textColor="text-primary"
            paddingY="py-3"
            border
          >
            Load More
          </Button>
        </div>
      </div>
    </>
  );
};

export default Transactions;
