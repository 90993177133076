import React, { useState } from "react";

import Button from "../../inputs/Button";
import TextInput from "../../inputs/TextInput";
import Tick from "../../../assets/svg/green-bg-tick.svg";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Loading from "../../common/Loading";

const SetDriversLicense = () => {
  const [form, setForm] = useState(1);
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const val = e.target.value;

    if (val?.match(/^[a-zA-Z0-9]+$/)) {
      setId(val);
    }
  };

  const handleSetID = async () => {
    setIsLoading(true);
    await handleFetch({
      endpoint: "/user/set-dvl",
      method: "POST",
      body: { id },
      auth: true,
    })
      .then((res) => {
        if (res.data.status) {
          setForm(2);
          const user = JSON.parse(localStorage.getItem("u"));
          localStorage.setItem("u", JSON.stringify({ ...user, id }));
        }
        notification({
          title: "Error",
          message:
            res.msgd?.toString() ||
            "An error occurred while trying to set drivers license. Please, try again",
          type: "danger",
        });
      })
      .catch((error) => {
        notification({
          title: "Error",
          message:
            error?.toString() ||
            "An error occurred while trying to set drivers license. Please, try again",
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl font-bold mb-5">Set Your Drivers License</h2>

          <div className="w-full">
            <div className="mb-10">
              <TextInput
                value={id}
                onChange={handleChange}
                className="w-full mb-5"
                label="Drivers License"
              />
            </div>

            <Button
              onClick={handleSetID}
              className="w-full mt-10"
              disabled={!id}
              paddingY="py-3"
            >
              Submit Drivers License
            </Button>
          </div>
        </div>
      )}

      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500">
                You have successfully setup your drivers license
              </p>
            </div>

            <Button
              onClick={() => window.location.reload()}
              className="w-full mt-5"
              paddingY="py-3"
            >
              Okay
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default SetDriversLicense;
