/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
// import Webcam from 'react-webcam';
import Modal from "react-responsive-modal";
import {
  IoIosArrowForward,
  IoIosWarning,
  IoIosCheckmarkCircle,
} from "react-icons/io";
import { HiOutlineIdentification } from "react-icons/hi";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Button from "../../inputs/Button";
import SetBVN from "./SetBVN";
import SetNIN from "./SetNIN";
import SetDriversLicense from "./SetDriversLicense";
// import WebCamComponent from './WebCamComponent';

const LegalIdentity = () => {
  // const [user, setUser] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [paramsSvm, setParamsSvm] = useState("");
  const [showActions, setShowActions] = useState(false);
  const [showBvnModal, setShowBvnModal] = useState(false);
  const [showNinModal, setShowNinModal] = useState(false);
  const [showIdModal, setShowIdModal] = useState(false);
  const [showSecondVerificationModal, setShowSecondVerificationModal] = useState(false);
  const [identities, setIdentities] = useState({
    dvl: {
      name: "Drivers License",
      number: "",
      id: "",
      match_score: null,
      slug: "dvl",
      status: false,
    },
    nin: {
      name: "National Identification Number (NIN)",
      number: "",
      id: "",
      match_score: null,
      slug: "nin",
      status: false,
    },
    bvn: {
      name: "Bank verification Number (BVN)",
      number: "",
      id: "",
      match_score: null,
      slug: "bvn",
      status: false,
    },
  });

  useEffect(() => {
    // const u = JSON.parse(localStorage.getItem('u'));
    // setUser(u);
    const getData = async () => {
      // setIsLoading(true);
      await handleFetch({ endpoint: "/user/wallet" })
        .then((res) => {
          // setData(res?.data);
          const identityObj = res?.data;
          // eslint-disable-next-line no-nested-ternary
          identityObj.identities?.map((item) =>
            item.category.slug === "dvl"
              ? setIdentities({
                ...identities,
                dvl: {
                  name: identities.dvl.name,
                  id: item.category.id,
                  number: item.id_number,
                  match_score: item.match_score,
                  slug: identities.dvl.slug,
                  match: item.status,
                },
              })
              : item.category.slug === "bvn"
                ? setIdentities({
                  ...identities,
                  bvn: {
                    name: identities.bvn.name,
                    id: item.category.id,
                    number: item.id_number,
                    match_score: item.match_score,
                    slug: identities.bvn.slug,
                    match: item.status,
                  },
                })
                : item.category.slug === "nin"
                  ? setIdentities({
                    ...identities,
                    nin: {
                      name: identities.nin.name,
                      id: item.category.id,
                      number: item.id_number,
                      match_score: item.match_score,
                      slug: identities.nin.slug,
                      match: item.status,
                    },
                  })
                  : setIdentities({ ...identities }));
        })
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
      // setIsLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (type) => {
    if (type === "dvl") {
      setShowIdModal(true);
    } else if (type === "bvn") {
      setShowBvnModal(true);
    } else if (type === "nin") {
      setShowNinModal(true);
    } else if (type === "svm") {
      setShowSecondVerificationModal(true);
    }
  };

  return (
    <>
      <div className="w-full mb-10">
        <Button
          className={`mb-4 w-full ${showActions ? "bg-gray-200" : ""}`}
          onClick={() => setShowActions(!showActions)}
          bgColor="bg-white"
          textColor="text-gray-500 dark:text-black"
          paddingY="py-2"
          paddingX="px-5"
          border
          borderColor="border-gray-300"
        >
          <HiOutlineIdentification className="mr-6 w-8 h-auto" />
          Legal Identity
        </Button>
        {showActions && (
          <ul className="w-full mb-5">
            {Object.keys(identities).map((item) => (
              <li
                role="presentation"
                className="list-btn dark:text-white dark:hover:text-black cursor-pointer"
                onClick={() => {
                  if (!identities[item].status) {
                    openModal(identities[item].slug);
                  } else {
                    openModal("svm");
                    setParamsSvm(identities[item].id);
                  }
                }}
              >
                < >
                  <span className="font-bold">
                    {identities[item].name}
                  </span>
                  {identities[item].match ? (
                    identities[item].match_score ? (
                      <IoIosCheckmarkCircle className="w-6 text-green-500 h-auto" />
                    ) : (
                      identities[item].match && (
                        <span className="flex">
                          <IoIosCheckmarkCircle className="w-6 text-yellow-400 h-auto" />
                          <IoIosArrowForward className="ml-2 w-6 h-auto text-gray-400" />
                        </span>
                      )
                    )
                  ) : (
                    <span className="flex">
                      <IoIosWarning className="ml-10 w-6 h-auto text-error" />
                      <IoIosArrowForward className="ml-2 w-6 h-auto text-gray-400" />
                    </span>
                  )}
                </>
              </li>
            ))}
          </ul>
        )}
      </div>

      <Modal
        classNames={{ modal: "rounded-md no-padding w-full sm:w-96" }}
        open={showBvnModal}
        closeOnOverlayClick={false}
        onClose={() => setShowBvnModal(false)}
        center
      >
        <SetBVN closeModal={() => setShowBvnModal(false)} />
      </Modal>

      <Modal
        classNames={{ modal: "rounded-md no-padding w-full sm:w-96" }}
        open={showNinModal}
        closeOnOverlayClick={false}
        onClose={() => setShowNinModal(false)}
        center
      >
        <SetNIN closeModal={() => setShowNinModal(false)} />
      </Modal>
      <Modal
        classNames={{ modal: "rounded-md no-padding w-full sm:w-96" }}
        open={showIdModal}
        closeOnOverlayClick={false}
        onClose={() => setShowIdModal(false)}
        center
      >
        <SetDriversLicense closeModal={() => setShowIdModal(false)} />
      </Modal>
      {showSecondVerificationModal && (
        <Redirect to={`/aspiro/second-level-verification/${paramsSvm}`} />
      )}
    </>
  );
};

export default LegalIdentity;
