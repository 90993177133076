import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import ThemeContext from "../../../contexts/ThemeContext";
import AspiroLogo from "../../../assets/images/aspiro-sec-logo.png";
import MonieworxLogo from "../../../assets/images/moneiworx-sec-logo.png";
import TextInput from "../../inputs/TextInput";
import Button from "../../inputs/Button";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Loading from "../../common/Loading";

const LoginForm = ({ logout }) => {
  const history = useHistory();
  const { pathname } = history?.location;

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { mode, changeTheme } = useContext(ThemeContext);
  const ma = useContext(ThemeContext);

  useEffect(() => {
    if (localStorage.getItem("err")) {
      notification({
        title: "Error",
        message: localStorage.getItem("err"),
        type: "danger",
      });
      localStorage.clear();
    }
  }, []);

  const handleLogin = async () => {
    if (!(username || password)) return;
    setIsLoading(true);

    await handleFetch({
      endpoint: "login",
      method: "POST",
      body: { username, password },
    })
      .then((res) => {
        localStorage.setItem("t", res?.data?.token);
        localStorage.setItem("u", JSON.stringify(res?.data?.user));

        setTimeout(() => {
          notification({
            message: `Welcome back ${res?.data?.user?.f_name}`,
            type: "success",
          });
          history.push("/home");
        }, 1000);
      })
      .catch((error) => {
        setIsLoading(false);
        notification({
          title: "Error",
          message: error?.toString(),
          type: "danger",
        });
      });
  };

  const handleKeyPress = (e) => {
    if (!isLoading && e.which === 13) {
      handleLogin();
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <div className="w-full h-full min-h-screen flex dark:bg-black">
        <div
          role="presentation"
          className="absolute right-3 top-3 cursor-pointer"
          onClick={() => {
            changeTheme();
          }}
        >
          {mode ? (
            <BsFillSunFill className="text-3xl text-yellow" />
          ) : (
            <BsFillMoonStarsFill className="text-2xl text-blue-800" />
          )}
        </div>
        <div className="w-full max-w-xl m-auto p-10">
          <div className="text-gray-500">
            <img
              alt="Logo"
              src={pathname.includes("aspiro") ? AspiroLogo : MonieworxLogo}
              className="w-40 h-auto mb-5 mx-auto"
            />
            <p className="text-lg text-center dark:text-white text-slate">
              {logout ? "Successfully logged out." : "Welcome!"}
              &nbsp;Please login to your account.
            </p>
            <div className="w-full mt-7">
              <TextInput
                label="Email / Phone Number"
                value={username || ""}
                onChange={(e) => setUsername(e?.target?.value)}
                onKeyPress={handleKeyPress}
                className="w-full mb-5 dark:text-white"
              />
              <TextInput
                label="Password"
                value={password || ""}
                onChange={(e) => setPassword(e?.target?.value)}
                type="password"
                onKeyPress={handleKeyPress}
                className="w-full mb-10 dark:text-white"
              />

              <Button
                className="w-full dark:text-black dark:bg-yellow"
                paddingY="py-4"
                onClick={handleLogin}
              >
                Login
              </Button>

              <div className="flex justify-between mt-3 mb-10 dark:text-white">
                <Link className="hover:text-primary" to="/sign-up">
                  New User? Register
                </Link>
                <Link
                  className="hover:text-primary dark:text-red-600"
                  to="/forget-password"
                >
                  Forget Password?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
