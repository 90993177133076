/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { PaystackButton } from "react-paystack";
import Button from "../../inputs/Button";
// import TextInput from '../../inputs/TextInput';
import Tick from "../../../assets/svg/green-bg-tick.svg";
import Loading from "../../common/Loading";
import notification from "../../../utilities/notification";
import handleFetch from "../../../api/handleFetch";

const AddCard = () => {
  const [form, setForm] = useState(1);
  // const [reference, setReference] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState([]);
  const [users, setUsers] = useState(JSON.parse(localStorage.getItem("u")));
  const [config, setConfig] = useState({
    reference: "",
    email: "",
    amount: 5000,
    publicKey: "",
  });

  useEffect(() => {
    const u = JSON.parse(localStorage.getItem("u"));
    setUsers(u);

    const getData = async () => {
      await handleFetch({ endpoint: "/user/wallet/cards/provider-info" })
        .then((res) => {
          setConfig({
            ...config,
            email: users?.email,
            reference: res.data.reference,
            publicKey: res.data.public_key,
          });
        })
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
    };
    getData();
    // eslint-disable-next-line
  }, []);

  // const handlePaystackSuccessAction = (ref) => {
  //   // Implementation for whatever you want to do with reference and after success call.
  //   handleAddCard(ref.reference);
  //   setForm(2);
  // };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
  };

  const componentProps = {
    ...config,
    text: "Click to add new card",
    onSuccess: (ref) => handleAddCard(ref.reference),
    onClose: handlePaystackCloseAction,
  };

  //   const handleChange = (e) => {
  //     const val = e.target.value;
  //     setReference(val);
  //   };

  const handleAddCard = async (ref) => {
    setIsLoading(true);
    // const refTwo = `T${reference}`;
    await handleFetch({
      endpoint: "/user/wallet/cards",
      method: "POST",
      body: { reference: ref },
      auth: true,
    })
      .then((res) => {
        setForm(2);
        const user = JSON.parse(localStorage.getItem("u"));
        localStorage.setItem("u", JSON.stringify({ ...user }));
      })
      .catch((error) => {
        notification({
          title: "Error",
          message:
            error?.toString() ||
            "An error occurred while trying to set BVN. Please, try again",
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl font-bold mb-5 dark:text-white">Add Card</h2>

          {/* <div className="w-full">
            <div className="mb-10">
              <TextInput
                value={reference}
                onChange={handleChange}
                className="w-full mb-5"
                label="Please type in you card number"
              />
            </div>

            <Button
              onClick={handleAddCard}
              className="w-full mt-10"
              disabled={!reference}
              paddingY="py-3"
            >
              Submit BVN
            </Button>
          </div> */}

          <PaystackButton {...componentProps} />
        </div>
      )}

      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500">
                You have successfully added your Card
              </p>
            </div>

            <Button
              onClick={() => window.location.reload()}
              className="w-full mt-5"
              paddingY="py-3"
              paddingX="px-3"
            >
              Okay
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default AddCard;
