import React, { useEffect, useState } from "react";

import LegalIdentity from "./LegalIdentity";
import BankAccounts from "./BankAccounts";
import Tokens from "./Tokens";
import ATMCards from "./ATMCards";
import Reciepts from "./Reciepts";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";

const WalletContainer = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      await handleFetch({ endpoint: "/user/wallet" })
        .then((res) => {
          setData(res?.data);
        })
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
    };

    getData();
  }, []);

  return (
    <div className="w-full md:w-2/3 xl:w-1/2 mx-auto">
      <div className="w-full">
        <LegalIdentity data={data?.identities} />
        <BankAccounts data={data?.bank_accounts} />
        <ATMCards data={data?.bank_cards} />
        <Tokens data={data?.tokens} />
        <Reciepts data={data?.certificates} />
      </div>
    </div>
  );
};

export default WalletContainer;
