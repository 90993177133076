import React, { useEffect, useState } from "react";

import GoalCard from "./GoalCard";

import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Loading from "../../common/Loading";

const Goals = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await handleFetch({ endpoint: "/fetch-monieworx-goals" })
        .then((res) => setData(res))
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
      setIsLoading(false);
    };

    getData();
  }, []);

  return (
    <>
      {isLoading && <Loading />}

      <div className="w-full md:w-2/3 xl:w-1/2 mx-auto">
        <GoalCard data={data?.investment_goals} />
      </div>
    </>
  );
};

export default Goals;
