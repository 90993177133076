import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { pure } from "recompose";

import CartContext from "../../contexts/CartContext";
import notification from "../../utilities/notification";

import Cart from "./Cart";
import InvestmentHome from "./InvestmentHome";

const { theme } = localStorage;

const HomeIndex = pure(() => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const store = localStorage.getItem("investment-cart");
    store?.includes("id") && setCart(JSON.parse(store));
  }, []);

  useEffect(() => {
    localStorage.setItem("investment-cart", JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product) => {
    setCart((state) => {
      const newState = [
        ...new Map([product, ...state].map((item) => [item.id, item])).values(),
      ];

      if (newState?.length === state?.length) {
        notification({
          message: `${product?.title?.toUpperCase()} already exist in your cart.`,
          type: "warning",
        });
      } else {
        notification({
          message: `You have successfully added ${product?.title?.toUpperCase()} to your cart.`,
          type: "success",
        });
      }
      return newState;
    });
  };

  const removeItem = (id) => {
    let list = [...cart];
    list = list?.filter((item) => item?.id !== id);
    setCart(list);
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        clearCart,
        removeItem,
      }}
    >
      <Switch>
        <Route path="/home" exact component={InvestmentHome} />
        <Route path="/home/cart" exact component={Cart} />
      </Switch>
    </CartContext.Provider>
  );
});

export default HomeIndex;
