import React, { Fragment, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";
import { MdNotifications, MdExpandMore } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import ThemeContext from "../../contexts/ThemeContext";
import handleFetch from "../../api/handleFetch";
import Avatar from "../../assets/images/avatar.png";

const TopBar = ({ header = "Header", name = "N/A" }) => {
  const history = useHistory();
  const { pathname } = history?.location;
  const [user, setUser] = useState([]);
  const { mode, changeTheme } = useContext(ThemeContext);

  const getUserData = async () => {
    await handleFetch({
      endpoint: "/user",
      method: "GET",
    }).then((result) => {
      localStorage.setItem("u", JSON.stringify(result?.data));
      setUser(result?.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const redirectPage = (e, type) => {
    e.preventDefault();
    if (type === "email") {
      history.push(
        pathname.includes("aspiro")
          ? "/aspiro/verify-email"
          : "/verify-email"
      );
    }
    if (type === "phone") {
      history.push(
        pathname.includes("aspiro")
          ? "/aspiro/verify-phone"
          : "/verify-phone"
      );
    }
  };

  const handleLogout = async () => {
    localStorage.clear();
    history.push(
      pathname.includes("aspiro") ? "/aspiro/logout" : "/logout"
    );
  };

  return (
    <div className="fixed left-0 w-full dark:bg-black lg:pl-64 bg-white z-10">
      <div className="w-full flex justify-between items-center dark:shadow-red-500 dark:blackSecondary h-16 shadow-md  dark:border-b-2 border-solid border-sky-500  px-10 pl-20 lg:pl-10">
        <h2 className="font-black dark:text-white text-xl mr-5">{header}</h2>

        <div className="flex items-center text-primary-gray-2">
          <div
            role="presentation"
            className="cursor-pointer"
            onClick={() => {
              changeTheme();
            }}
          >
            {mode ? (
              <BsFillSunFill className="text-3xl text-yellow" />
            ) : (
              <BsFillMoonStarsFill className="text-2xl text-blue-800" />
            )}
          </div>
          <Menu
            as="div"
            className="dark:text-white relative inline-block text-left"
          >
            <div>
              <Menu.Button className="inline-flex justify-center p-2 text-sm font-medium rounded-md focus:outline-none hover:bg-gray-100">
                <MdNotifications className="w-5 h-auto" />
              </Menu.Button>
            </div>
            {user?.user?.phone_verified_at ? (
              <></>
            ) : (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    {user?.user?.email_verified_at ? null : (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={(e) => {
                              redirectPage(e, "email");
                            }}
                            className={`${
                              active ? "bg-primary text-white" : ""
                            } group flex rounded-md items-center w-full px-2 py-1.5 my-1 text-sm`}
                          >
                            {user?.user?.email_verified_at
                              ? null
                              : "Verify email here!"}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {user?.user?.phone_verified_at ? null : (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={(e) => {
                              redirectPage(e, "phone");
                            }}
                            className={`${
                              active ? "bg-primary text-white" : ""
                            } group flex rounded-md items-center w-full px-2 py-1.5 my-1 text-sm`}
                          >
                            {user?.user?.phone_verified_at
                              ? " "
                              : "Verify phone number here!"}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            )}
          </Menu>
          <div className="mr-3 ">
            <Menu
              as="div"
              className="relative dark:text-white inline-block text-left"
            >
              <div>
                <Menu.Button className="inline-flex justify-center p-2 text-sm font-medium rounded-md focus:outline-none hover:bg-gray-100 dark:hover:bg-black">
                  {name}
                  <MdExpandMore
                    className="w-5 h-5 ml-2 -mr-1 dark:text-white text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-40 mt-2 origin-top-right dark:bg-black bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={handleLogout}
                          className={`${
                            active
                              ? "bg-primary dark:bg-yellow dark:text-black text-white"
                              : ""
                          } group flex rounded-md items-center w-full px-2 py-1.5 my-1 text-sm`}
                        >
                          <BiLogOut
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          <div className="w-10 h-10 rounded-full">
            <img src={Avatar} className="w-full h-full" alt="Avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
