/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import Tick from "../../../assets/svg/green-bg-tick.svg";

import Button from "../../inputs/Button";
import CartContext from "../../../contexts/CartContext";
import { formatCurrency } from "../../../utilities/general";
import Loading from "../../common/Loading";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";

const MakeInvestment = ({ total = 0, closeModal = () => {} }) => {
  // const { cart, clearCart } = useContext(CartContext);
  const [form, setForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [showRiskModal, setShowRiskModal] = useState(false);
  const [purchase, setPurchase] = useState({
    offer_id: null,
    amount: null,
  });

  useEffect(() => {}, []);

  const handleBuyToken = async () => {
    setIsLoading(true);
    await handleFetch({
      endpoint: "/offers/checkout",
      method: "POST",
      body: {
        purchase,
      },
      auth: true,
    })
      .then((res) => {
        // setForm(2);
        // clearCart();
        const user = JSON.parse(localStorage.getItem("u"));
        localStorage.setItem(
          "u",
          JSON.stringify({
            ...user,
            formatted_balance: formatCurrency(user?.wallet?.balance - total),
          })
        );
      })
      .catch((error) => {
        notification({
          title: "Error",
          message: error?.toString(),
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading message="Processing Transactions..." />}
      {form === 1 && (
        <center className="w-full mt-5 px-10">
          <h2 className="text-xl font-bold mb-5">Buy Token</h2>

          <div className="w-full">
            <div className="mb-5">
              <p className="text-gray-500 mb-5">
                The total amount (<b>{formatCurrency(total)}</b>) stated will be
                immediately deducted from your wallet.
              </p>
              <p className="text-gray-500">
                View tokens bought in your Portfolio
              </p>
              <div className="inline-flex mt-5">
                <input
                  type="checkbox"
                  name=""
                  className="mr-2 mt-1 mb-10 self-start cursor-pointer"
                  checked={isCheck}
                  onChange={(e) => setIsCheck(!isCheck)}
                  id=""
                />
                <p>
                  By continuing, you agree to our
                  <span
                    className="ml-1 cursor-pointer underline decoration-1"
                    onClick={(e) => setShowRiskModal(true)}
                    role="presentation"
                  >
                    Risks Policy
                  </span>
                </p>
              </div>
            </div>

            <Button
              onClick={handleBuyToken}
              className="w-full mt-10"
              paddingY="py-3"
              disabled={!isCheck}
              bgColor="bg-white"
              textColor="text-primary"
              border
            >
              Yes, Buy Token
            </Button>
          </div>
        </center>
      )}

      <Modal
        center
        open={showRiskModal}
        closeOnOverlayClick={false}
        onClose={() => setShowRiskModal(false)}
        classNames={{ modal: "rounded-md no-padding" }}
      >
        <div className="w-72 sm:w-96 mb-5">
          <p className="font-bold">RISK WARNING</p>
          <p>
            All persons who register as an 'investor' on this website should
            read carefully the following warnings before making any investment.
          </p>
          <p>
            Monieworx is the trading name for Obelix 4.1.1 Alternative Finance
            Company Limited . Monieworx is an SEC-licensed crowdfunding portal
            in Nigeria.
          </p>
          <p>
            Monieworx does not, expressly, or impliedly, directly or indirectly,
            guarantee or make any representation or warranty concerning the
            completeness, adequacy or accuracy of this risk disclosures notice.
            This brief statement does not disclose all of the risks and other
            significant aspects of investing. You should, therefore, carefully
            study and consider the merits and demerits and take independent
            professional advice before becoming involved in investment
            transactions. You should undertake transactions only if you
            understand the nature of the contractual relationship into which you
            are entering and the extent of your exposure to risk, keeping in
            mind your financial resources.
          </p>
          <p>
            A decision to invest is a personal decision by you and that no
            responsibility for the consequences of that decision is accepted by
            Monieworx or by any of its directors, agents, employees or other
            members.
          </p>
          <p>
            To invest through Monieworx you need to understand the following:
          </p>
          <ol>
            <li className="mt-2">
              <span className="block font-bold">1. APPROPRIATENESS</span>
              <p>
                Before opening an account for you, we are required to make an
                assessment of whether it is appropriate for you, and to warn
                you, on the basis of the information you provide to us, whether
                the services offered by us are appropriate for you. Any decision
                on whether or not to open an account, and on whether or not you
                understand the risks, is yours.
              </p>
            </li>
            <li className="mt-2">
              <span className="block font-bold">2. FEES AND CHARGES</span>
              <p>
                Our costs and charges will be provided to you or set out on our
                website. Please be aware of all costs and charges that apply to
                you or the Fundraisers, because such costs and charges will
                affect your return on investment.
              </p>
            </li>
            <li className="mt-2">
              <span className="block font-bold">
                3. LOSING ALL OF YOUR INVESTMENT
              </span>
              <p>
                Investments in startups, early-stage ventures, emerging
                technology companies funds, SME businesses, and digital asset
                projects are speculative and carry high risks as well as the
                possibility of high rewards. Unlike an investment in a mature
                business, where there is a track record of revenue and income,
                the success of a startup, early-stage venture or emerging
                technology company often relies on the development of a new
                product or service that may or may not find a market. You should
                be prepared to lose your entire investment.
              </p>
              <p>
                Accordingly, each investor should consider very carefully
                whether such investments are suitable in the light of personal
                circumstances and commitments and the financial resources
                available to each Investor. Monieworx does not promise any
                return on investment nor that the value of any investment be
                maintained. Engaging in any investment activity may expose you
                to a significant risk of losing all of your investment.
              </p>
              <p>
                Most of the Fundraising companies that are listed on Monieworx
                are new companies with a limited track record. These companies
                will provide information such as their business plan and
                financial forecasts. Please be warned that these documents are
                not guarantees that the relevant company can achieve what it is
                hoping to do. Equally the information provided may state certain
                facts and statements, and again please be warned that Monieworx
                is not responsible for checking the accuracy of these facts and
                statements (which may not always prove to be true or complete).
              </p>
              <p>
                If a company you invest in fails, neither the company – nor
                Monieworx – will pay back your investment.
              </p>
            </li>
            <li className="mt-2">
              <span className="block font-bold">
                4. THE NEED FOR DIVERSIFICATION - NOT PUTTING YOUR EGGS IN ONE
                BASKET
              </span>
              <p>
                Due to the risks noted above, we strongly recommend that
                investors spread the money they are putting into crowdfunding
                across a broad range of opportunities. Doing this will reduce
                the effect of any individual investment underperforming or
                failing and should therefore reduce your overall risk of
                financial loss.
              </p>
              <p>
                The advantages of diversification also apply to the range of
                investments you choose and where you invest with members of your
                immediate family - you should only place a proportion of your
                funds into crowdfunding and, as a family, aim for a broad spread
                of different investments between you.
              </p>
            </li>
            <li className="mt-2">
              <span className="block font-bold">5. WARNING</span>
              <p>
                Prospective investors should note that past performance should
                not be seen as an indication of future performance. The value of
                an investment and the income from it can fall as well as rise
                and Investors may not get back the amount originally invested.
                Therefore you should only make investments in unlisted companies
                or with businesses which you can afford to lose without having
                any significant impact on your overall financial position or
                commitments. Taxation levels, bases and reliefs may change if
                the law changes and independent advice should be sought. We will
                not have any liability for any legal, investment or tax issues
                in connection with any investment you decide to make through our
                platform or from the information we have provided you.
              </p>
            </li>
            <li className="mt-2">
              <span className="block font-bold">
                6. NO ESTABLISHED MARKET - LACK OF LIQUIDITY
              </span>
              <p>
                As an investor, your ability to resell your investment in the
                first year will be restricted with narrow exceptions. You should
                be aware that no established market exists for the trading of
                shares in private companies (which the companies that are listed
                on the Monieworx are), and such shares are not easily realisable
                as such, you may need to hold your investment for an indefinite
                period of time. There may also be a restriction on the ability
                to sell your shares set out in any shareholder agreement
                effected as part of the fundraise. It must be appreciated that
                there could be difficulty in selling such investments at a
                reasonable price and, in some circumstances, it may be difficult
                to sell them at any price.
              </p>
            </li>
            <li className="mt-2">
              <span className="block font-bold">MISCELLANEOUS</span>
              <p>By using Monieworx, you further understand and agree that</p>
              <ol>
                <li className="mt-1">
                  i. that the proposed investment is a risky investment;
                </li>
                <li className="mt-1">
                  ii. you may be overpaying for the securities or investment
                  contracts issued;
                </li>
                <li className="mt-1">
                  iii. you are able to economically absorb the complete loss of
                  the investment;
                </li>
                <li className="mt-1">
                  iv. the proposed investment amount falls within the prescribed
                  investment limit for your investment category;
                </li>
                <li className="mt-1">
                  v. you will be subject to a lock-in period of at least one
                  year and may be subject to a further restriction on the
                  transfer of the securities;
                </li>
                <li className="mt-1">
                  vi. you may never be able to sell the securities or investment
                  instruments,
                </li>
                <li className="mt-1">
                  vii. you will be provided with no disclosure or less
                  disclosure than publicly traded companies;
                </li>
                <li className="mt-1">
                  viii. you will not have the benefit of protections associated
                  with an investment made under a prospectus;
                </li>
                <li className="mt-1">
                  ix. you will not be entitled to claim from the National
                  Investor Protection Fund;
                </li>
                <li className="mt-1">
                  x. Monieworx will not be responsible if the investor loses all
                  or some of the money invested.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Modal>

      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500 font-bold text-lg">
                Trade Successful!
              </p>
            </div>

            <Button
              onClick={closeModal}
              className="w-full mt-10"
              paddingY="py-3"
              bgColor="bg-white"
              textColor="text-primary"
              border
            >
              Okay
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default MakeInvestment;
