import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";
import { BsFillArrowUpSquareFill } from "react-icons/bs";

const PortfolioCard = ({
  header = "",
  logo,
  amount = "0",
  percent,
  type,
  typeText,
  bgColor = "#F0F0F0",
}) => (
  <div
    // style={{ backgroundColor: bgColor }}
    className="w-full bg-[#F0F0F0] h-full dark:bg-secondary rounded-md shadow px-5 py-4 flex flex-col font-bold justify-between"
  >
    <div className="flex justify-end">
      <>{logo}</>
    </div>

    <p className="font-extrabold dark:text-white sm:text-2xl text-xl mb-2">
      {amount}
    </p>
    {type && (
      <div className="flex mb-2">
        <BsFillArrowUpSquareFill className="text-[#0097a7] dark:text-[#8CD867] text-xl" />
        <span className="ml-2 text-[#0097a7] dark:text-[#8CD867]">
          {percent}%
        </span>
      </div>
    )}
    {/* <h3 className="text-base capitalize mb-3">{header}</h3> */}
    <Link
      to="/portfolio"
      className="flex items-center w-max text-primary"
    >
      <span className="font-bold dark:text-white text-lg text-black">
        {typeText}
      </span>
      <span className="ml-2 text-xl">
        <IoIosArrowDropright className="dark:text-[#8CD867]" />{" "}
      </span>
    </Link>
  </div>
);

export default PortfolioCard;
