import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { IoFilter } from "react-icons/io5";
import { BsArrowUpCircleFill } from "react-icons/bs";
import Button from "../../inputs/Button";
import { formatCurrency } from "../../../utilities/general";
import CartButton from "./CartButton";
import Invest from "./Invest";

const Investments = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const selectItem = (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setCurrentItem(null);
    setShowModal(false);
  };

  return (
    <>
      <div className="w-full mb-5">
        <div className="w-full flex justify-between items-center">
          <h3 className="text-lg dark:text-white font-bold mb-1">
            Featured Investment
          </h3>
          <IoFilter className="text-xl" />
        </div>

        <div className="w-full">
          {data?.map((item) => (
            <div
              key={item?.id}
              role="presentation"
              onClick={() => selectItem(item)}
              className="xs:flex cursor-pointer bg-[#F0F0F0] dark:bg-secondary rounded-lg justify-between sm:p-4 p-2 mb-4 items-center"
            >
              <div className="flex items-center">
                <div className="pr-2 sm:pr-0">
                  <img src={item.issuer_logo} alt="" className="w-44 h-auto" />
                </div>
                <div className="ml-5">
                  <p className="font-bold dark:text-white text-xl">
                    ₦{item?.max_amount}
                    <span className=" text-sm font-normal">
                      ({item.percent_raised}% raised)
                    </span>
                  </p>
                  <p className="font-bold dark:text-white">
                    {item?.percent_raised}% Interest in {item?.tenure_in_days}{" "}
                    days
                  </p>
                  <p className="dark:text-white">
                    Supply Chain - Q21 Solutions
                  </p>
                </div>
              </div>
              <div className="">
                <BsArrowUpCircleFill className="text-2xl text-[#0097A7] dark:text-[#8CD867]" />
                <p className="text-[#0097a7] dark:text-[#8CD867] font-extrabold">
                  Buy
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        center
        open={showModal}
        closeOnOverlayClick={false}
        onClose={closeModal}
        classNames={{ modal: "rounded-md no-padding" }}
      >
        <div className="w-72 sm:w-96 mb-5">
          <Invest data={currentItem} closeModal={closeModal} />
        </div>
      </Modal>
    </>
  );
};

export default Investments;
