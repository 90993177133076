import React, { useEffect, useState } from "react";

import TopBar from "../components/common/TopBar";

const ViewWrapper = ({ header = "", children = null } = {}) => {
  const [name, setName] = useState("N/A");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Monieworx - ${header}`;

    const u = JSON.parse(localStorage.getItem("u"));
    setName(u?.user?.f_name);
  }, [header]);

  return (
    <div className="w-full lg:pl-64 dark:bg-black">
      <TopBar header={header} name={name} />

      <div className="w-full max-w-screen-xl min-h-screen px-1 l:px-10 md:px-10 pt-24">
        <div className="w-full pb-20 relative">
          <div className="w-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ViewWrapper;
