import React from "react";

import Sidebar from "../components/common/Sidebar";

export default function NavigableLayout({ children }) {
  return (
    <div className="w-full h-full min-h-screen relative">
      <Sidebar />
      {children}
    </div>
  );
}
