import React from "react";

import { Switch } from "@headlessui/react";

const Toggle = ({ value, onChange }) => (
  <Switch
    checked={value}
    onChange={onChange}
    className={`${
      value ? "bg-blue-600" : "bg-gray-200"
    } relative inline-flex items-center h-6 rounded-full w-11`}
  >
    <span className="sr-only">Enable</span>
    <span
      className={`${
        value ? "translate-x-6" : "translate-x-1"
      } inline-block w-4 h-4 transform bg-white rounded-full`}
    />
  </Switch>
);

export default Toggle;
