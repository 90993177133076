/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";
// import { useHistory } from 'react-router-dom';
import Modal from "react-responsive-modal";
import {
  IoIosArrowForward,
  IoIosCheckmarkCircle,
  IoIosClose,
} from "react-icons/io";

import Avatar from "../../../assets/images/avatar.png";
import Button from "../../inputs/Button";
import UserProfile from "./UserProfile";
import ChangePassword from "./ChangePassword";
import SetAppPreference from "./SetAppPreference";
import VerifyEmail from "../auth/VerifyEmail";
import VerifyPhone from "../auth/VerifyPhone";

const AccountContainer = () => {
  // const history = useHistory();
  // const { pathname } = history?.location;

  const [user, setUser] = useState({});
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showSetPreference, setShowSetPreference] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [verifyPhone, setVerifyPhone] = useState(false);

  useEffect(() => {
    const u = JSON.parse(localStorage.getItem("u"));
    setUser(u);
  }, []);

  const listBtnStyle = (pointer = true) =>
    `list-btn ${pointer ? "cursor-pointer" : ""}`;

  const closeBtn = <IoIosClose className="dark:text-white text-3xl" />;
  return (
    <>
      <div className="w-full">
        <div className="w-full flex flex-wrap justify-between items-center">
          <div className="flex dark:text-white items-center mb-10">
            <img
              src={Avatar}
              alt="profile avatar"
              className="mr-5 w-12 h-12 rounded-full"
            />
            <div className="">
              <h3 className="text-lg font-bold mb-2">{`${user?.f_name || ""} ${
                user?.l_name || ""
              }`}</h3>
              <Button
                bgColor="bg-white"
                textColor="text-gray-500"
                onClick={() => setShowUserDetails(true)}
                fontSize="text-xs"
                paddingY="py-1.5"
                border
              >
                Edit Profile
              </Button>
            </div>
            <IoIosArrowForward className="ml-10 w-8 h-auto text-gray-400" />
          </div>

          {/* <Button
            className="mb-10 justify-between w-full sm:w-auto"
            onClick={() => (pathname.includes('aspiro')
              ? history.push('/home') : history.push('/aspiro/home'))}
            bgColor="bg-white"
            textColor="text-primary"
            paddingY="py-3"
            paddingX="px-5"
            border
          >
            {`Switch to ${pathname.includes('aspiro') ? 'Investment' : 'Loan'} App`}
            <IoIosArrowForward className="ml-10 w-6 h-auto" />
          </Button> */}
        </div>
      </div>

      <div className="w-full">
        <div className="flex flex-wrap -mx-3 mb-3">
          <div className="py-5 px-3 w-full md:w-1/2">
            <div className="">
              <h3 className="mb-3 dark:text-white font-bold text-lg text-gray-500">
                SETTINGS
              </h3>
              <ul className="w-full">
                <li
                  onClick={() => setShowSetPreference(true)}
                  role="presentation"
                  className={`${listBtnStyle()} dark:text-white dark:hover:text-black`}
                >
                  <span className="font-bold">App Preferences</span>
                  <IoIosArrowForward className="ml-10 w-6 h-auto text-gray-400" />
                </li>
              </ul>
            </div>
            <div className="w-full mt-10">
              <h3 className="mb-3 font-bold text-lg dark:text-white text-gray-500">
                SECURITY
              </h3>
              <ul className="w-full">
                <li
                  onClick={() => setShowChangePassword(true)}
                  role="presentation"
                  className={`${listBtnStyle()} dark:text-white dark:hover:text-black`}
                >
                  <span className="font-bold">Change Password</span>
                  <IoIosArrowForward className="ml-10 w-6 h-auto text-gray-400" />
                </li>

                <li
                  onClick={() =>
                    user.email_verified_at
                      ? setVerifyEmail(false)
                      : setVerifyEmail(true)
                  }
                  role="presentation"
                  className={`${listBtnStyle()} dark:text-white dark:hover:text-black`}
                >
                  {/* <Link to={(user.email_verified_at && pathname.includes('aspiro')
                    ? '/!' : history.push('/moniworx/verify-email'))}
                  >
                    {' '}
                    <span className="font-bold">Email verification</span>
                    {user.email_verified_at ? (<IoIosCheckmarkCircle className="w-6 text-green-400 h-auto" />) : <IoIosArrowForward className="ml-10 w-6 h-auto text-gray-400" />}

                  </Link> */}
                  <span className="font-bold">Email Verification</span>
                  {user.email_verified_at ? (
                    <IoIosCheckmarkCircle className="w-6 text-green-400 h-auto" />
                  ) : (
                    <p className="font-bold text-green-500">Verify</p>
                  )}
                </li>
                <li
                  onClick={() =>
                    user.phone_verified_at
                      ? setVerifyPhone(false)
                      : setVerifyPhone(true)
                  }
                  role="presentation"
                  className={`${listBtnStyle()} dark:text-white dark:hover:text-black`}
                >
                  <span className="font-bold">Phone Verification</span>
                  {user.phone_verified_at ? (
                    <IoIosCheckmarkCircle className="w-6 text-green-400 h-auto" />
                  ) : (
                    <p className="font-bold text-green-500">Verify</p>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div className="py-5 px-3 w-full md:w-1/2">
            <div className="w-full">
              <h3 className="mb-3 font-bold dark:text-white text-lg text-gray-500">
                ABOUT APP
              </h3>
              <ul className="w-full">
                <li
                  className={`${listBtnStyle()} dark:text-white dark:hover:text-black`}
                >
                  <span className="font-bold">Terms & Conditions</span>
                  <IoIosArrowForward className="ml-10 w-6 h-auto text-gray-400" />
                </li>
                <li
                  className={`${listBtnStyle()} dark:text-white dark:hover:text-black`}
                >
                  <span className="font-bold">FAQs</span>
                  <a href="https://moneyworx.ganbaru.xyz/faqs/" target="_blank">
                    <IoIosArrowForward className="ml-10 w-6 h-auto text-gray-400" />
                  </a>
                </li>
                <li
                  className={`${listBtnStyle()} dark:text-white dark:hover:text-black`}
                >
                  <span className="font-bold">Contact Us</span>
                  <a href="https://moneyworx.ganbaru.xyz/" target="_blank">
                    <IoIosArrowForward className="ml-10 w-6 h-auto text-gray-400" />
                  </a>
                </li>
                <li
                  className={`${listBtnStyle()} dark:text-white dark:hover:text-black`}
                >
                  <span className="font-bold">Visit Our Website</span>
                  <a href="https://moneyworx.ganbaru.xyz/" target="_blank">
                    <IoIosArrowForward className="ml-10 w-6 h-auto text-gray-400" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        center
        open={showUserDetails}
        closeIcon={closeBtn}
        closeOnOverlayClick={false}
        onClose={() => setShowUserDetails(false)}
        classNames={{
          modal: "rounded-md no-padding dark:text-white dark:bg-black",
        }}
      >
        <div className="w-72 sm:w-96 mb-5">
          <UserProfile
            data={user}
            closeModal={() => setShowUserDetails(false)}
          />
        </div>
      </Modal>

      <Modal
        center
        open={showChangePassword}
        closeOnOverlayClick={false}
        closeIcon={closeBtn}
        onClose={() => setShowChangePassword(false)}
        classNames={{
          modal: "rounded-md no-padding dark:text-white dark:bg-black",
        }}
      >
        <div className="w-72 sm:w-96 mb-5">
          <ChangePassword closeModal={() => setShowChangePassword(false)} />
        </div>
      </Modal>

      <Modal
        center
        open={verifyPhone}
        closeOnOverlayClick={false}
        closeIcon={closeBtn}
        onClose={() => setVerifyPhone(false)}
        classNames={{
          modal: "rounded-md dark:text-white no-padding dark:bg-black",
        }}
      >
        <div className="w-full h-70 sm:w-96">
          <VerifyPhone closeModal={() => setVerifyPhone(false)} />
          {/* <VerifyEmail closeModal={() => setVerifyEmail(false)} /> */}
        </div>
      </Modal>
      <Modal
        center
        open={verifyEmail}
        closeIcon={closeBtn}
        closeOnOverlayClick={false}
        onClose={() => setVerifyEmail(false)}
        classNames={{
          modal: "rounded-md dark:text-white no-padding dark:bg-black",
        }}
      >
        <div className="w-full h-70 sm:w-96">
          <VerifyEmail closeModal={() => setVerifyEmail(false)} />
        </div>
      </Modal>

      <Modal
        center
        open={showSetPreference}
        closeIcon={closeBtn}
        closeOnOverlayClick={false}
        onClose={() => setShowSetPreference(false)}
        classNames={{
          modal: "rounded-md dark:text-white no-padding dark:bg-black",
        }}
      >
        <div className="w-72 sm:w-96">
          <SetAppPreference
            data={user}
            closeModal={() => setShowSetPreference(false)}
          />
        </div>
      </Modal>
    </>
  );
};

export default AccountContainer;
