import React from "react";
import Modal from "react-responsive-modal";

import Preloader from "../../assets/svg/preloader.svg";

const Loading = ({ message }) => (
  <Modal
    classNames={{
      modal: "rounded-md no-padding dark:bg-black dark:text-white",
    }}
    open
    showCloseIcon={false}
    onClose={() => {}}
    center
  >
    <center className="p-3 pb-5 rounded bg-white dark:bg-black dark:text-white">
      <div className="">
        <img
          src={Preloader}
          className="w-40 dark:fill-white h-auto"
          alt="circle"
        />
      </div>
      <p className="text-base ">{message || "Loading..."}</p>
    </center>
  </Modal>
);

export default Loading;
