import React, { useEffect } from "react";
import AccountContainer from "../components/containers/account/Account";

import ViewWrapper from "../layouts/ViewWrapper";

const Account = () => {
  useEffect(() => {}, []);

  return (
    <ViewWrapper header="Account">
      <AccountContainer />
    </ViewWrapper>
  );
};

export default Account;
