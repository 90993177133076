import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const TextInput = ({
  pattern,
  className,
  name,
  label,
  type,
  value,
  onChange,
  readOnly,
  disabled,
  onBlur,
  onKeyDown,
  onKeyPress,
  minDate,
  maxDate,
  maxLength,
  placeholder,
  title,
}) => {
  const [inputType, setInputType] = useState(type || "text");

  return (
    <div className={`${className || ""} form-group relative`}>
      <label htmlFor={name} className="text-sm block pb-1">
        {label}
      </label>
      <input
        type={inputType}
        name={name}
        id={name}
        value={value}
        className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 hover:border-alat-dark"
        onChange={onChange}
        readOnly={readOnly}
        onBlur={onBlur}
        disabled={disabled}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        min={minDate}
        max={maxDate}
        maxLength={maxLength}
        autoComplete="off"
        placeholder={placeholder || ""}
        pattern={pattern}
        title={title}
      />
      {type === "password" && (
        <span className="absolute right-3 bottom-3.5 text-gray-700">
          {inputType === "password" ? (
            <AiFillEye
              className="w-6 h-auto cursor-pointer"
              onClick={() => setInputType("text")}
            />
          ) : (
            <AiFillEyeInvisible
              className="w-6 h-auto cursor-pointer"
              onClick={() => setInputType("password")}
            />
          )}
        </span>
      )}
    </div>
  );
};

export default TextInput;
