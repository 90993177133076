import React, { useEffect } from "react";

import GoalsContainer from "../../components/containers/goals/Goals";
import ViewWrapper from "../../layouts/ViewWrapper";

const Goals = () => {
  useEffect(() => {}, []);

  return (
    <ViewWrapper header="Investment Goals">
      <GoalsContainer />
    </ViewWrapper>
  );
};

export default Goals;
