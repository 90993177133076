import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { formatCurrency } from "../../../utilities/general";
import Button from "../../inputs/Button";

import ItemDetails from "./ItemDetails";

const Investments = ({ data = [] }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const selectItem = (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setCurrentItem(null);
    setShowModal(false);
  };

  return (
    <>
      <div className="w-full mb-5">
        {data.map((item) => (
          <div
            key={item?.id}
            role="presentation"
            onClick={() => selectItem(item)}
            className="xs:flex rounded-lg bg-[#F0F0F0] dark:bg-secondary justify-between sm:p-4 p-2 mb-2 items-center"
          >
            <div className="pr-2 sm:pr-0">
              <img src={item.offer.issuer_logo} alt="" />
            </div>
            <div className="">
              <p className="font-bold dark:text-white">
                ₦{item?.offer?.max_amount}
              </p>
              <p className="dark:text-white">
                {item?.offer?.interest_rate}% Interest in{" "}
                {item?.offer.tenure_in_days} days
              </p>
              <p className="dark:text-white">Supply Chain - Q21 Solutions</p>
            </div>
            <Button
              bgColor="bg-[#0097A7] dark:text-black dark:bg-[#8CD867] "
              className="h-1/3"
            >
              ₦{item?.offer?.invested_amount}
            </Button>
          </div>
        ))}
      </div>

      <Modal
        center
        open={showModal}
        closeOnOverlayClick={false}
        onClose={closeModal}
        classNames={{ modal: "rounded-md no-padding" }}
      >
        <div className="w-72 sm:w-96 mb-5">
          <ItemDetails data={currentItem} closeModal={closeModal} />
        </div>
      </Modal>
    </>
  );
};

export default Investments;
