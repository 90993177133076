import React, { useState } from "react";

import Button from "../../inputs/Button";
import Toggle from "../../inputs/Toggle";
import Tick from "../../../assets/images/success.png";

import Loading from "../../common/Loading";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";

const SetAppPreference = ({ data = {}, closeModal }) => {
  const [form, setForm] = useState(1);
  const [enableNotification, setEnableNotification] = useState(
    data?.allow_notifs || false
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    const body = { state: enableNotification };

    await handleFetch({
      endpoint: "/user/notifications/set",
      method: "POST",
      body,
    })
      .then((res) => {
        setForm(2);
        localStorage.setItem(
          "u",
          JSON.stringify({
            ...data,
            allow_notifs: enableNotification,
          })
        );
      })
      .catch((err) => {
        notification({
          title: "Error",
          message: err?.toString(),
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}

      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl font-bold">App Preference</h2>

          <div className="w-full mt-10">
            <div className="w-full flex justify-between items-center">
              <span className="font-bold">Enable Notification</span>
              <Toggle
                value={enableNotification}
                onChange={() => setEnableNotification(!enableNotification)}
              />
            </div>

            <Button
              className="w-full mt-10"
              onClick={handleSubmit}
              paddingY="py-3"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      )}

      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500">
                You have successfully&nbsp;
                {enableNotification ? "enabled" : "disabled"}
                &nbsp;your app notification
              </p>
            </div>

            <Button
              onClick={() => window.location.reload()}
              className="w-full mt-5"
              paddingY="py-3"
            >
              Okay
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default SetAppPreference;
