import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import qs from "query-string";

import Card from "./Card";
import Investments from "./Investments";
import StatusSelector from "../../inputs/Selector";
import Tabs from "../../common/Tabs";

import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Loading from "../../common/Loading";

const PortfolioContainer = () => {
  const history = useHistory();
  const query = qs.parse(history?.location?.search);

  const [statusFilter, setStatusFilter] = useState("All");
  const [tab, setTab] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortedList, setSortedList] = useState(null);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await handleFetch({ endpoint: "/user/investments" })
        .then((res) => setData(res?.data))
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
      setIsLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    const pending = [];
    const fulfilled = [];
    data?.investments?.forEach((item) => {
      item?.fulfilled ? fulfilled.push(item) : pending.push(item);
    });
    setSortedList({ fulfilled, pending });
  }, [data]);

  useEffect(() => {
    if (query?.tab) {
      if (query?.tab === "fulfilled") setTab(0);
      if (query?.tab === "pending") setTab(1);
    } else setTab(0);
  }, [query]);

  return (
    <>
      {isLoading && <Loading />}
      <div className="w-full md:w-2/3 xl:w-1/2 mx-auto">
        <div className="mb-5">
          <Card data={data} />
        </div>

        <div className="w-full">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg dark:text-white font-bold mb-1">Investments</h3>
            <StatusSelector
              value={statusFilter}
              onChange={(val) => setStatusFilter(val)}
              options={["All", "Mature"]}
            />
          </div>

          {/* <div className="mb-5">
            <Tabs
              activeTab={tab}
              activeTabBackgroundColor="bg-primary"
              tabs={[
                {
                  label: "Fulfilled",
                  onClick: () =>
                    history.push("/portfolio?tab=fulfilled"),
                },
                {
                  label: "Pending",
                  onClick: () =>
                    history.push("/portfolio?tab=pending"),
                },
              ]}
            />
          </div> */}

          <div className="w-full mb-5">
            {tab === 0 && <Investments data={sortedList?.fulfilled} />}
            {tab === 1 && <Investments data={sortedList?.pending} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioContainer;
