import React from "react";
import { Route, Switch } from "react-router-dom";

import Aspirations from "./Aspirations";
import Goals from "./Goals";
// import Wishlist from './Wishlist';

const Index = () => (
  <Switch>
    <Route path="/goals/goals" exact component={Goals} />
    <Route path="/goals/aspirations" exact component={Aspirations} />

    <Route path="/aspiro/goals" exact component={Goals} />
    <Route path="/aspiro/goals/aspirations" exact component={Aspirations} />
  </Switch>
);

export default Index;
