import React, { useEffect, useState } from "react";
import Select from "react-select";

import "../../assets/style/input-styles.scss";

const SelectInput = ({
  className,
  name,
  label,
  value,
  onChange,
  disabled,
  options,
  readOnly,
  onBlur,
  onKeyDown,
  onKeyPress,
  multiple,
  placeholder,
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (
      typeof options?.[0] === "object" &&
      !Array.isArray(options?.[0]) &&
      options?.[0] !== null
    ) {
      setList(options);
    } else {
      const newList = options?.map((item) => ({ value: item, label: item }));
      setList(newList);
    }
  }, [options]);

  return (
    <div className={`${className || ""} form-group`}>
      <label htmlFor={name} className="text-sm block pb-1">
        {label}
      </label>
      <Select
        className="select"
        classNamePrefix="react-select"
        name={name}
        id={name}
        value={value}
        isMulti={multiple}
        isSearchable
        onChange={onChange}
        isDisabled={disabled}
        options={list || []}
        readOnly={readOnly}
        onBlur={onBlur}
        disabled={disabled}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        placeholder={placeholder || ""}
      />
    </div>
  );
};

export default SelectInput;
