import React from "react";
import { Route, Switch } from "react-router-dom";

import Transactions from "./Transactions";
import Wallet from "./Wallet";

const Index = () => (
  <Switch>
    <Route path="/wallet/items" exact component={Wallet} />
    <Route
      path="/wallet/transactions"
      exact
      component={Transactions}
    />

    <Route path="/aspiro/wallet/items" exact component={Wallet} />
    <Route path="/aspiro/wallet/transactions" exact component={Transactions} />
  </Switch>
);

export default Index;
