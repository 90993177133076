import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ThemeWrapper({ children }) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("aspiro")) {
      document.documentElement.classList.add("aspiro");
    } else {
      document.documentElement.classList.remove("aspiro");
    }
  }, [location.pathname]);

  return <>{children}</>;
}

export default ThemeWrapper;
