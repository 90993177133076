/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function RouteWrapper({
  component: Component,
  layout: Layout,
  ...rest
}) {
  const t = localStorage.getItem("t");

  return (
    <Route
      {...rest}
      render={(props) =>
        t ? (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/" />
        )}
    />
  );
}
