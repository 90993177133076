import React, { useEffect, useState } from "react";
import { BsCreditCard } from "react-icons/bs";
import Modal from "react-responsive-modal";
import { FiPlus } from "react-icons/fi";
import notification from "../../../utilities/notification";
import Button from "../../inputs/Button";
import CheckBox from "../../inputs/CheckBox";
import handleFetch from "../../../api/handleFetch";
import Visa from "../../../assets/images/visa-logo.png";
import Master from "../../../assets/images/master-logo.png";
import GreenCard from "../../../assets/images/green-card.png";
// import BlueCard from '../../../assets/images/blue-card.png';
// import BlackCard from '../../../assets/images/black-card.png';
import AddCard from "./AddCard";

const ATMCards = () => {
  const [user, setUser] = useState({});
  const [showActions, setShowActions] = useState(false);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [currentCard, setCurrentCard] = useState("");
  const [cards, setCards] = useState([]);

  const getData = async () => {
    await handleFetch({ endpoint: "/user/wallet/cards" })
      .then((res) => {
        setCards(res.data);
      })
      .catch((err) => {
        notification({
          title: "Error",
          message: err?.toString(),
          type: "danger",
        });
      });
  };

  useEffect(() => {
    const u = JSON.parse(localStorage.getItem("u"));
    setUser(u);

    getData();
  }, []);

  const setPrimaryCard = async (id) => {
    await handleFetch({
      endpoint: "/user/wallet/cards/mark-as-primary",
      method: "PUT",
      body: { card_id: id },
    })
      .then((res) => {
        notification({
          title: "Success",
          message: res.msg?.toString(),
          type: "success",
        });
        getData();
      })
      .catch((err) => {
        notification({
          title: "Error",
          message: err?.toString(),
          type: "danger",
        });
      });
  };

  return (
    <>
      <div className="w-full mb-10">
        <Button
          className={`mb-4 w-full ${showActions ? "bg-gray-200" : ""}`}
          onClick={() => setShowActions(!showActions)}
          bgColor="bg-white"
          textColor="text-gray-500 dark:text-black"
          paddingY="py-2"
          paddingX="px-5"
          border
          borderColor="border-gray-300"
        >
          <BsCreditCard className="mr-6 w-8 h-auto" />
          Cards
        </Button>
        {showActions && (
          <div className="w-full mb-5">
            <div className="w-full mb-3">
              <img
                src={GreenCard}
                alt="green card"
                className="w-full h-auto cursor-pointer"
                role="presentation"
                onClick={() => setShowCardDetails(true)}
              />
            </div>
            {cards?.map((item) => (
              <div
                key={item.id}
                className="w-full flex justify-between dark:text-white dark:hover:text-black items-center py-4 px-1 border-b last:border-0 hover:bg-gray-100 cursor-pointer"
                role="presentation"
                onClick={() => {
                  setCurrentCard(item.id);
                  setPrimaryCard(item.id);
                }}
              >
                <div className="flex items-center">
                  <img
                    src={item.card_type === "visa" ? Visa : Master}
                    alt="master card"
                    className="w-12 mr-4 h-auto"
                  />
                  <div className="">
                    <h3 className="text-lg font-bold">{item.bank}</h3>
                    <p className="text-base text-gray-400">{`**** ${item.last_four_digits} `}</p>
                  </div>
                </div>

                <CheckBox
                  label=""
                  // checked={currentCard === item.id ? currentCard === item.id : item.primary}
                  checked={
                    item.primary ? item.primary : currentCard === item.id
                  }
                  className="-top-3"
                />
              </div>
            ))}
            <Button
              className="flex items-center mt-3"
              onClick={() => setAddCard(!addCard)}
            >
              <FiPlus className="mr-3 text-lg" />
              Add Card
            </Button>
          </div>
        )}
      </div>

      <Modal
        classNames={{ modal: "rounded-md no-padding w-full sm:w-96" }}
        center
        closeOnOverlayClick={false}
        open={addCard}
        onClose={() => setAddCard(false)}
      >
        <AddCard />
      </Modal>

      <Modal
        classNames={{ modal: "rounded-md no-padding w-full sm:w-96" }}
        open={showCardDetails}
        closeOnOverlayClick={false}
        onClose={() => setShowCardDetails(false)}
        center
      >
        <div className="w-full">
          <h2 className="text-xl text-center font-bold mb-5">Card Details</h2>

          <div className="w-full">
            <div className="w-full flex justify-between items-center p-2">
              <p className="">Card Name:</p>
              <p className="text-xl font-bold">{`${user?.f_name} ${user?.l_name}`}</p>
            </div>
            <div className="w-full flex justify-between items-center p-2">
              <p className="">Card Number:</p>
              <p className="text-xl font-bold">3449 4499 3993 0200</p>
            </div>
            <div className="w-full flex justify-between items-center p-2">
              <p className="">CVV:</p>
              <p className="text-xl font-bold">099</p>
            </div>
            <div className="w-full flex justify-between items-center p-2">
              <p className="">Expiry:</p>
              <p className="text-xl font-bold">09/2025</p>
            </div>
            <div className="w-full flex justify-between items-center p-2">
              <p className="">Card Bank:</p>
              <p className="text-xl font-bold">Zenith Bank</p>
            </div>
            <div className="w-full flex justify-between items-center p-2">
              <p className="">Status:</p>
              <p className="text-xl font-bold">Active</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ATMCards;
