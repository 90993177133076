/* eslint-disable max-len */
import React from "react";

const Button = ({
  children = "Button",
  className = "",
  onClick = () => {},
  disabled = false,
  fontSize = "text-sm",
  borderColor = "border-primary",
  bgColor = "bg-primary ",
  textColor = "text-white ",
  paddingY = "py-2",
  paddingX = "px-6",
  border = false,
}) => (
  <button
    className={`${fontSize} ${bgColor} ${textColor} ${paddingX} ${paddingY} rounded font-bold ${
      border ? `border ${borderColor}` : ""
    } flex justify-center items-center transition ease-in-out hover:bg-opacity-70 disabled:border-0 ${className} dark:bg-yellow dark:text-black disabled:cursor-default disabled:bg-opacity-30 disabled:bg-primary disabled:text-white`}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
