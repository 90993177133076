import React, { useState } from "react";
import { FaCoins } from "react-icons/fa";

import Button from "../../inputs/Button";

const Reciepts = ({ data }) => {
  const [showActions, setShowActions] = useState(false);

  return (
    <>
      <div className="w-full mb-10">
        <Button
          className={`mb-4 w-full ${showActions ? "bg-gray-200" : ""}`}
          onClick={() => setShowActions(!showActions)}
          bgColor="bg-white"
          textColor="text-gray-500 dark:text-black"
          paddingY="py-2"
          paddingX="px-5"
          border
          borderColor="border-gray-300"
        >
          <FaCoins className="mr-6 w-8 h-auto" />
          Certificates
        </Button>

        {showActions && (
          <div className="w-full mb-5">
            {data?.map((item) => (
              <a href={item?.url} target="_blank" rel="noreferrer" key={item?.id} className="cursor-pointer">
                <div className="w-full flex justify-between items-center dark:text-white dark:hover:text-black  py-4 px-1 border-b last:border-0 hover:bg-gray-100 cursor-pointer">
                  <div className="w-full flex items-center">
                    <FaCoins className="w-12 mr-4 h-auto dark:text-black p-3 bg-blue-100 rounded-full" />
                    <div style={{ width: "calc(100% - 64px)" }} className="overflow-hidden">
                      <h3 className="text-lg font-bold">{item?.title} - {item?.invested_amount_formatted}</h3>
                      <p className="text-base text-gray-400 line-clamp-1">
                        {item?.url || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            ))}
            {data?.length < 1 && (
              <center>No Certificate</center>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Reciepts;
