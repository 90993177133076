import React, { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { MdExpandMore } from "react-icons/md";

const Selector = ({ value = "", options = [], onChange = () => {} }) => (
  <Menu as="div" className="relative  inline-block text-left">
    <div>
      <Menu.Button className="inline-flex dark:text-[#8CD867] justify-center text-sm text-primary font-bold focus:outline-none">
        {value}
        <MdExpandMore
          className="w-5 h-5 ml-1 -mr-1 dark:text-[#8CD867] text-violet-200 hover:text-violet-100"
          aria-hidden="true"
        />
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute dark:bg-secondary right-0 w-max mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="px-1 py-1">
          {options?.map((item) => (
            <Menu.Item key={item}>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() => onChange(item)}
                  className={`${
                    active || item === value
                      ? "bg-primary dark:bg-[#8CD867] dark:text-black text-white"
                      : ""
                  } group flex dark:text-white items-center w-full px-3 my-1 rounded py-1 text-sm`}
                >
                  {item}
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
);

export default Selector;
