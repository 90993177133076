import React from "react";

import "../../assets/style/input-styles.scss";

const CheckBox = ({
  name,
  value,
  checked,
  onChange,
  label,
  type = "checkbox",
  className,
}) => (
  <div className={`${className} my-custom-checkbox`}>
    <input
      type={type}
      name={name}
      id={value}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={value}>{label}</label>
  </div>
);

export default CheckBox;
