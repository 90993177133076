import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { useLocation } from "react-router-dom";
// import { BiUpvote } from 'react-icons/bi';
import { IoIosClose } from "react-icons/io";
// import { RiDeleteBin5Line } from 'react-icons/ri';
// import { GrEdit } from 'react-icons/gr';
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Loading from "../../common/Loading";
import Button from "../../inputs/Button";
import AddAspiration from "./AddAspiration";
import EditAspiration from "./EditAspiration";

const Aspirations = () => {
  const location = useLocation();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await handleFetch({
        endpoint: location?.pathname.includes("aspiro")
          ? "/user/aspirations?page=1"
          : "/user/aspirations?page=1",
      })
        .then((res) => {
          setData(res?.data?.data);
        })
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
      setIsLoading(false);
    };

    getData();
  }, [location]);

  const updateList = (item) => {
    setData([item, ...data]);
  };

  const closeBtn = <IoIosClose className="dark:text-white text-3xl" />;

  const handleEdit = (editem) => {
    const newState = data.map((obj) => {
      if (obj.id === editem.id) {
        return { ...obj, header: editem.title, write_up: editem.body };
      }

      return obj;
    });
    setData(newState);
  };

  const handleDelete = async (id) => {
    setData(data.filter((item) => item.id !== id));
    await handleFetch({
      endpoint: location?.pathname.includes("aspiro")
        ? "/user/aspirations"
        : "/user/aspirations",
      method: "DELETE",
      body: {
        id,
      },
    })
      .then((res) => {
        notification({
          title: "Success",
          message: res.msg?.toString(),
          type: "success",
        });
      })
      .catch((err) => {
        notification({
          title: "Error",
          message: err?.toString(),
          type: "danger",
        });
      });
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="w-full md:w-2/3 xl:w-1/2 mx-auto">
        {data?.map((item) => (
          <div className="mb-5 pb-2 border-b dark:text-white" key={item?.id}>
            <h3 className="font-bold text-base mb-1">{item?.header}</h3>
            <p className="break-words">{item?.write_up}</p>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <div className="flex items-center mr-2">
                  {/* <BiUpvote className="text-green-500 h-auto" /> */}
                  <p className="pt-1 mr-1">{`${item.upvotes} Upvotes`}</p>
                </div>
                <div className="flex items-center">
                  {/* <BiDownvote className="text-green-500 h-auto" /> */}
                  <p className="pt-1 mr-1">{`${item.upvotes} Downvotes`}</p>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  type="button"
                  className="mr-3"
                  onClick={() => {
                    setShowEditModal(true);
                    setEditItem(item);
                  }}
                >
                  Edit
                </button>
                {/* <RiDeleteBin5Line onClick={() => handleDelete(item.id)} /> */}
                <button
                  type="button"
                  className="text-red-500"
                  onClick={() => handleDelete(item.id)}
                >
                  Delete{" "}
                </button>{" "}
              </div>
            </div>
          </div>
        ))}

        <Button
          onClick={() => setShowModal(true)}
          className="w-full dark:bg-yellow dark:text-black dark:border-yellow"
          bgColor="bg-white"
          textColor="text-primary"
          border
          paddingY="py-3"
        >
          Add Aspiration
        </Button>
      </div>

      <Modal
        center
        closeIcon={closeBtn}
        open={showModal}
        closeOnOverlayClick={false}
        onClose={() => setShowModal(false)}
        classNames={{
          modal: "rounded-md dark:text-white no-padding dark:bg-black",
        }}
      >
        <div className="w-72 sm:w-96">
          <AddAspiration
            updateList={updateList}
            onClose={() => setShowModal(false)}
          />
        </div>
      </Modal>
      <Modal
        center
        closeIcon={closeBtn}
        open={showEditModal}
        closeOnOverlayClick={false}
        onClose={() => {
          setShowEditModal(false);
        }}
        classNames={{
          modal: "rounded-md dark:text-white no-padding dark:bg-black",
        }}
      >
        <div className="w-72 sm:w-96">
          <EditAspiration
            updateList={(item) => {
              handleEdit(item);
            }}
            item={editItem}
            onClose={() => setShowEditModal(false)}
          />
        </div>
      </Modal>
    </>
  );
};

export default Aspirations;
