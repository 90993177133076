import React, { useState } from "react";

import Button from "../../inputs/Button";
import TextInput from "../../inputs/TextInput";
import Tick from "../../../assets/svg/green-bg-tick.svg";
import Loading from "../../common/Loading";
import notification from "../../../utilities/notification";
import handleFetch from "../../../api/handleFetch";

const SetBVN = () => {
  const [form, setForm] = useState(1);
  const [bvn, setBnv] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const val = e.target.value;

    if (val?.match(/^\d*$/)) {
      setBnv(val);
    }
  };

  const handleSetBvn = async () => {
    setIsLoading(true);
    await handleFetch({
      endpoint: "/user/set-bvn",
      method: "POST",
      body: { bvn },
      auth: true,
    })
      .then((res) => {
        setForm(2);
        const user = JSON.parse(localStorage.getItem("u"));
        localStorage.setItem("u", JSON.stringify({ ...user, bvn }));
      })
      .catch((error) => {
        notification({
          title: "Error",
          message:
            error?.toString() ||
            "An error occurred while trying to set BVN. Please, try again",
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl font-bold mb-5">Set Your BVN</h2>

          <div className="w-full">
            <div className="mb-10">
              <TextInput
                value={bvn}
                onChange={handleChange}
                className="w-full mb-5"
                label="Bank Verification Number (BVN)"
              />
            </div>

            <Button
              onClick={handleSetBvn}
              className="w-full mt-10"
              disabled={!bvn}
              paddingY="py-3"
            >
              Submit BVN
            </Button>
          </div>
        </div>
      )}

      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500">
                You have successfully setup your BVN
              </p>
            </div>

            <Button
              onClick={() => window.location.reload()}
              className="w-full mt-5"
              paddingY="py-3"
            >
              Okay
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default SetBVN;
