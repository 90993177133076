/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";

import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Loading from "../../common/Loading";
import Tick from "../../../assets/svg/green-bg-tick.svg";

import Button from "../../inputs/Button";
import CheckBox from "../../inputs/CheckBox";
import TextInput from "../../inputs/TextInput";

const SetGoals = ({ data }) => {
  const u = JSON.parse(localStorage.getItem("u"));
  const [form, setForm] = useState(1);
  const [amount, setAmount] = useState(data?.amount || "");
  const [riskAppetite, setRiskAppetite] = useState(
    u?.investment_goal?.risk_appetite || "low"
  );
  const [categoryList, setCategoryList] = useState([]);
  const [categories, setCategories] = useState(
    (u?.investment_goal?.categories &&
      JSON.parse(u?.investment_goal?.categories)) ||
      []
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getCategories = async () => {
      setIsLoading(true);
      await handleFetch({ endpoint: "/investment-goal-categories" })
        .then((res) => {
          setCategoryList(res.data);
        })
        .catch((err) => {
          notification({
            title: "Error",
            message: err?.toString(),
            type: "danger",
          });
        });
      setIsLoading(false);
    };

    getCategories();
  }, []);

  const handleChange = (e) => {
    const val = e.target.value;

    if (val?.match(/^\d*$/)) {
      setAmount(val);
    }
  };

  const handleCategories = async (value) => {
    let selected = [];
    selected = [...categories];
    const find = selected.indexOf(value);

    find > -1 ? selected.splice(find, 1) : selected.push(value);
    setCategories(selected);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const body = { amount, categories, risk_appetite: riskAppetite };

    await handleFetch({
      endpoint: "/user/set-investment-goal",
      method: "POST",
      body,
    })
      .then((res) => {
        setForm(2);
        const user = JSON.parse(localStorage.getItem("u"));
        localStorage.setItem(
          "u",
          JSON.stringify({
            ...user,
            investment_goal: {
              ...body,
              categories: JSON.stringify(categories),
            },
          })
        );
      })
      .catch((err) => {
        notification({
          title: "Error",
          message: err?.toString(),
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      {u?.identities?.length === 0 && (
        <>
          <p className="dark:text-red-300 text-red-500">
            You can't fill this form, verify your BVN or NIN first!
          </p>{" "}
        </>
      )}
      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl font-bold mb-5 dark:text-white">
            Set Investment Goal
          </h2>

          <div className="w-full">
            <TextInput
              value={amount}
              onChange={handleChange}
              disabled={u?.identities?.length === 0}
              className="w-full mb-5 dark:text-white"
              label="Amount"
              placeholder="Enter investment goal(N)"
            />

            <div className="mb-5">
              <h4 className="font-bold mb-1 dark:text-white">
                Select Categories
              </h4>
              <ul className="ml-3">
                {categoryList?.map((item) => (
                  <li className="py-2.5" key={item?.id}>
                    <CheckBox
                      className="dark:text-white"
                      name="categories"
                      label={item?.category}
                      disabled={u?.identities?.length === 0}
                      value={item?.id}
                      onChange={() => handleCategories(item?.id)}
                      checked={categories?.includes(item?.id)}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-5">
              <h4 className="font-bold mb-1 dark:text-white">Risk Appetite</h4>
              <div className="flex justify-between py-2.5">
                <CheckBox
                  name="riskAppetite"
                  label="Low"
                  value="low"
                  className="dark:text-white"
                  onChange={() => setRiskAppetite("low")}
                  disabled={u?.identities?.length === 0}
                  checked={riskAppetite === "low"}
                />
                <CheckBox
                  name="riskAppetite"
                  label="Medium"
                  value="medium"
                  className="dark:text-white"
                  onChange={() => setRiskAppetite("medium")}
                  disabled={u?.identities?.length === 0}
                  checked={riskAppetite === "medium"}
                />
                <CheckBox
                  name="riskAppetite"
                  label="High"
                  value="high"
                  className="dark:text-white"
                  onChange={() => setRiskAppetite("high")}
                  disabled={u?.identities?.length === 0}
                  checked={riskAppetite === "high"}
                />
              </div>
            </div>

            <Button
              onClick={handleSubmit}
              className="w-full mt-5 dark:bg-yellow dark:text-black"
              paddingY="py-3"
              disabled={!amount || categories?.length < 1 || isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500">
                You have successfully setup your investment goal
              </p>
            </div>

            <Button
              onClick={() => window.location.reload()}
              className="w-full mt-5"
              paddingY="py-3"
            >
              Okay
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default SetGoals;
