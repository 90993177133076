import React, { useEffect } from "react";

import InvestmentDashboard from "../../components/containers/dashboard/InvestmentDashboard";
import ViewWrapper from "../../layouts/ViewWrapper";

const InvestmentHome = () => {
  useEffect(() => {}, []);

  return (
    <ViewWrapper header="Home">
      <InvestmentDashboard />
    </ViewWrapper>
  );
};

export default InvestmentHome;
