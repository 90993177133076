import React, { useEffect, useState } from "react";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import Button from "../../inputs/Button";

import Tick from "../../../assets/svg/green-bg-tick.svg";
import { formatCurrency } from "../../../utilities/general";
import { formatDate } from "../../../utilities/dateTime";

const ItemDetails = ({ data = {}, closeModal = () => {} }) => {
  const [form, setForm] = useState(1);

  useEffect(() => {}, []);

  return (
    <>
      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl text-center font-bold mb-5">
            Investment Details
          </h2>

          <div className="w-full">
            <div className="mb-5 border-b pb-3">
              <div className="w-full flex justify-between items-center p-2">
                <p className="">Title:</p>
                <p className="text-xl font-bold">{data?.offer?.title}</p>
              </div>
              <div className="w-full flex justify-between items-center p-2">
                <p className="">Invested amount:</p>
                <p className="text-xl font-bold">
                  {formatCurrency(data?.invested_amount)}
                </p>
              </div>
              <div className="w-full flex justify-between items-center p-2">
                <p className="">Interest Rate:</p>
                <p className="text-xl font-bold text-success">{`${data?.offer?.interest_rate}%`}</p>
              </div>
              <div className="w-full flex justify-between items-center p-2">
                <p className="">Maturity Status:</p>
                <p className="text-xl font-bold">
                  {data?.offer?.tenure}
                  &nbsp;month(s)
                </p>
              </div>
              {data?.payback_date && (
                <div className="w-full flex justify-between items-center p-2">
                  <p className="">Payback date:</p>
                  <p className="text-xl font-bold">
                    {formatDate(data?.payback_date)}
                  </p>
                </div>
              )}
              <div className="w-full flex justify-between items-center p-2">
                <p className="">Investment returns:</p>
                <p className="text-xl font-bold">
                  {formatCurrency(data?.investment_returns)}
                </p>
              </div>
              <div className="w-full flex justify-between items-center p-2">
                <p className="">Investment date:</p>
                <p className="text-xl font-bold">
                  {formatDate(data?.created_at)}
                </p>
              </div>
            </div>

            {data?.fulfilled && (
              <Button
                onClick={() => setForm(2)}
                className="w-full mt-10"
                paddingY="py-3"
              >
                Redeem
              </Button>
            )}
          </div>
        </div>
      )}

      {form === 2 && (
        <div className="w-full">
          <IoIosArrowBack
            onClick={() => setForm(1)}
            className="top-5 w-7 h-auto absolute cursor-pointer p-1 hover:bg-gray-200"
          />
          <h2 className="text-xl text-center font-bold mb-5">
            Redeem Investment
          </h2>

          <div className="w-full mb-10">
            <Button
              onClick={() => setForm(3)}
              className="w-full mt-10"
              bgColor="bg-white"
              textColor="text-primary"
              paddingY="py-3"
              paddingX="px-5"
              border
            >
              <span className="text-left">
                Redeem on crowd funding platform at the standard conversion
                rate.
              </span>
              <IoIosArrowForward className="ml-10 w-6 h-auto" />
            </Button>
            <Button
              onClick={() => setForm(3)}
              className="w-full mt-10"
              bgColor="bg-white"
              textColor="text-primary"
              paddingY="py-3"
              paddingX="px-5"
              border
            >
              <span className="text-left">
                Redeem in secondary market at the prevailing trading rate.
              </span>
              <IoIosArrowForward className="ml-10 w-6 h-auto" />
            </Button>
            <Button
              onClick={() => setForm(3)}
              className="w-full mt-10"
              bgColor="bg-white"
              textColor="text-primary"
              paddingY="py-3"
              paddingX="px-5"
              border
            >
              <span className="text-left">
                Redeem in int’l exchange having converted it to Int’l tokens
              </span>
              <IoIosArrowForward className="ml-10 w-6 h-auto" />
            </Button>
          </div>
        </div>
      )}

      {form === 3 && (
        <div className="w-full">
          <center className="w-full px-10">
            <div className="mb-5">
              <h2 className="text-xl text-center font-bold mt-5 mb-5">
                Trade Successful!
              </h2>
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500">
                We will send a reply soon, it will be in your notifications.
              </p>
            </div>

            <Button
              onClick={closeModal}
              className="w-full mt-5"
              paddingY="py-3"
            >
              Continue
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default ItemDetails;
