import React, { useState, useEffect } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { pure } from "recompose";

import ThemeContext from "../contexts/ThemeContext";
import NavigableLayout from "../layouts/NavigableLayout";
import RouteWrapper from "./RouteWrapper";

import NotFound from "../views/NotFound";
import Logout from "../views/auth/Logout";
import Login from "../views/auth/Login";
import HomeIndex from "../views/home/Index";
import Wallet from "../views/wallet/Index";
import Account from "../views/Account";
import Portfolio from "../views/Portfolio";
import Signup from "../views/auth/Signup";
import Goals from "../views/goals/Index";
import ThemeWrapper from "../components/ThemeWrapper";
import VerifyEmail from "../components/containers/auth/VerifyEmail";
import VerifyPhone from "../components/containers/auth/VerifyPhone";
import WebCamComponent from "../components/containers/wallet/WebCamComponent";

const { theme } = localStorage;

const App = pure(() => {
  const [mode, setMode] = useState(theme === "dark");
  const changeTheme = () => {
    setMode(!mode);
  };

  useEffect(() => {
    if (mode) {
      localStorage.setItem("theme", "dark");
      document.documentElement.classList.add("dark");
    } else {
      localStorage.setItem("theme", "light");
      document.documentElement.classList.remove("dark");
    }
  }, [mode]);

  return (
    <BrowserRouter>
      <ThemeWrapper>
        <ThemeContext.Provider
          value={{
            mode,
            changeTheme,
          }}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="/sign-up" exact component={Signup} />

            <Route path="/login" exact component={Login} />
            <Route path="/logout" exact component={Logout} />
            <Route
              path="/verify-email"
              exact
              component={VerifyEmail}
            />
            <Route
              path="/verify-phone"
              exact
              component={VerifyPhone}
            />
            <Route
              path="/second-level-verification/:id"
              exact
              component={WebCamComponent}
            />
            <RouteWrapper
              path="/home/"
              component={HomeIndex}
              layout={NavigableLayout}
            />
            <RouteWrapper
              path="/wallet/"
              component={Wallet}
              layout={NavigableLayout}
            />
            <RouteWrapper
              path="/goals/"
              component={Goals}
              layout={NavigableLayout}
            />
            <RouteWrapper
              path="/account"
              exact
              component={Account}
              layout={NavigableLayout}
            />
            <RouteWrapper
              path="/portfolio"
              exact
              component={Portfolio}
              layout={NavigableLayout}
            />
            <Route component={NotFound} />
          </Switch>
        </ThemeContext.Provider>
      </ThemeWrapper>
    </BrowserRouter>
  );
});

export default App;
