import React, { useState, useRef, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import Webcam from "react-webcam";
import imageCompression from "browser-image-compression";
import handleFetch from "../../../api/handleFetch";
import Button from "../../inputs/Button";
import notification from "../../../utilities/notification";

// Make this component more beautiful
const WebCamComponent = () => {
  const history = useHistory();
  const webcamRef = useRef(null);
  const { id } = useParams();
  //   const [isShowVideo, setIsShowVideo] = useState(true);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const handleClick = () => {
    history.push("/aspiro/home");
    compressPhoto(imgSrc);
  };

  const compressPhoto = (pic) => {
    // https://www.npmjs.com/package/browser-image-compression
    const options = {
      maxSizeMB: 0.5,
      // maxWidthOrHeight: 200,
      useWebWorker: true,
      // fileType: "image/png"
    };

    imageCompression
      .getFilefromDataUrl(pic)
      .then((file) => imageCompression(file, options))
      .then(toBase64)
      .then((newZipPic) => {
        const postData = async () => {
          // setIsLoading(true);
          await handleFetch({
            endpoint: "/user/verifyselfie",
            method: "POST",
            body: { photo_base64: newZipPic, identity_category_id: id },
            auth: true,
          })
            .then((res) => {
              notification({
                title: "Error",
                message: res.msg?.toString(),
                type: "danger",
              });
            })
            .catch((err) => {
              notification({
                title: "Error",
                message: err?.toString(),
                type: "danger",
              });
            });
          // setIsLoading(false);
        };
        postData();
      })
      .catch((error) => {
      });
  };

  const toBase64 = (img) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  return (
    <>
      <div className="flex justify-center flex-col items-center h-screen">
        <Webcam
          audio={false}
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          screenshotQuality={1}
          width={700}
          height={320}
          videoConstraints={videoConstraints}
        />
        <Button onClick={capture} className="w-1/6 mt-5" paddingY="py-3">
          Capture photo
        </Button>
        Please press the button to capture whenever you are ready
        {imgSrc && (
          <img
            style={{ display: "block" }}
            alt="selfie"
            width="150px"
            src={imgSrc}
          />
        )}
        {imgSrc && (
          <Button onClick={handleClick} className="w-1/6 mt-5" paddingY="py-3">
            Submit
          </Button>
        )}
      </div>
    </>
  );
};

export default WebCamComponent;
