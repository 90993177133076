import React, { useEffect, useState } from "react";
import { FaCoins } from "react-icons/fa";

import Button from "../../inputs/Button";

const Tokens = ({ data }) => {
  const [showActions, setShowActions] = useState(false);

  return (
    <>
      <div className="w-full mb-10">
        <Button
          className={`mb-4 w-full ${showActions ? "bg-gray-200" : ""}`}
          onClick={() => setShowActions(!showActions)}
          bgColor="bg-white"
          textColor="text-gray-500 dark:text-black"
          paddingY="py-2"
          paddingX="px-5"
          border
          borderColor="border-gray-300"
        >
          <FaCoins className="mr-6 w-8 h-auto" />
          Tokens
        </Button>

        {showActions && (
          <div className="w-full mb-5">
            {data?.tokens?.map((item) => (
              <div
                key={JSON.stringify(item)}
                className="w-full flex justify-between dark:text-white dark:hover:text-black items-center py-4 px-1 border-b last:border-0 hover:bg-gray-100 cursor-pointer"
              >
                <div className="flex items-center">
                  <FaCoins className="w-12 dark:text-black mr-4 h-auto p-3 bg-blue-100 rounded-full" />
                  <div className="">
                    <h3 className="text-lg font-bold">{item?.title}</h3>
                    <p className="text-base text-gray-400">{item?.amount}</p>
                  </div>
                </div>
              </div>
            ))}
            {data?.tokens?.length < 1 && (
              <center>No Token</center>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Tokens;
