import moment from "moment-timezone";

export const toggleScroll = () => {
  const overlays = document.querySelectorAll(".overlay");

  if (overlays.length > 0) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
};

export const formatCurrency = (value) => {
  if (value) {
    let val = value;
    val = val ? parseFloat(val).toFixed(2) : 0.0;
    return val === 0 ? "₦0.00" : `₦${Number(val).toLocaleString("en-US")}`;
  }
  return "₦0.00";
};

export const concealEmail = (email = "") =>
  email.replace(/(?!^).(?=[^@]+@)/g, "*");
export const concealValue = (value = "") =>
  value ? value.replace(/\w/g, "*") : "";

export const logger = (...logs) =>
  process.env.NODE_ENV === "development"
    ? console.log(...logs, `(Log time - ${moment().format("LLL")})`)
    : undefined;

export const format2Digits = (num) => {
  if (typeof num !== "number" || isNaN(num)) return 0;

  return (
    num.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) || 0
  );
};
