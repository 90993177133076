import React, { useEffect } from "react";

import WalletContainer from "../../components/containers/wallet/Wallet";
import ViewWrapper from "../../layouts/ViewWrapper";

const Wallet = () => {
  useEffect(() => {}, []);

  return (
    <ViewWrapper header="Wallet">
      <WalletContainer />
    </ViewWrapper>
  );
};

export default Wallet;
