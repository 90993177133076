import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  IoIosArrowBack,
  IoIosArrowDown,
} from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FiMenu } from "react-icons/fi";

import "../../assets/style/sidebar.scss";
import AspiroLogo from "../../assets/images/aspiro-pry-logo.png";
import MonieworxLogo from "../../assets/images/moneiworx-pry-logo.png";
import sidebarMenu from "../../configs/sidebarMenu";
import useClickOutsideBox from "../../hooks/useClickOutsideBox";

const Sidebar = () => {
  const history = useHistory();
  const { pathname } = history?.location;
  const menuList = sidebarMenu();
  const wrapperRef = useRef(null);

  const [showMenu, setShowMenu] = useState(false);

  useClickOutsideBox(wrapperRef, () => setShowMenu(false));

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const btnStyle =
    "transition ease-in-out w-9 p-1 h-auto bg-primary hover:text-white bg-opacity-5 text-gray-900 rounded hover:bg-opacity-100";

  return (
    <div ref={wrapperRef}>
      <div
        className={`${
          showMenu ? "left-52" : "left-6"
        } fixed cursor-pointer top-3 z-30 lg:hidden`}
      >
        {showMenu ? (
          <IoClose onClick={toggleMenu} className={btnStyle} />
        ) : (
          <FiMenu onClick={toggleMenu} className={btnStyle} />
        )}
      </div>

      <nav
        className={`z-20 page-sidebar dark:bg-black fixed w-64 bg-white h-screen shadow-md ${
          showMenu ? "show" : ""
        } border-solid border-r-2 border-sky-500`}
      >
        <div className="sidebar-header flex h-16 px-8 py-4 border-b shadow-md">
          <img
            src={pathname.includes("aspiro") ? AspiroLogo : MonieworxLogo}
            className="w-auto h-auto"
            alt="Logo"
          />
        </div>

        <div className="w-full sidebar-menu flex flex-col justify-between h-side-menu pt-4 pb-10">
          <ul className="menu-items">
            {menuList?.map((item) => (
              <MenuItem props={item} toggleMenu={toggleMenu} key={item.title} />
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

const MenuItem = ({
  props: { title, href, link, icon, children },
  toggleMenu,
}) => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const [isShowingSub, setIsShowingSub] = useState(false);

  return (
    <>
      {children ? (
        <li
          className={`${
            pathname?.includes(link) ? "active bg-primary" : ""
          } justify-between dark:text-white cursor-pointer hover:bg-primary`}
          onClick={() => setIsShowingSub(!isShowingSub)}
          role="presentation"
        >
          <span className="flex text-grey-500 hover:text-white">
            {icon}
            <span className={children ? "has-sub-menu pointer" : ""}>
              <span className="text-grey-400 title">{title}</span>
            </span>
          </span>
          {isShowingSub ? <IoIosArrowDown /> : <IoIosArrowBack />}
        </li>
      ) : (
        <>
          {href && (
            <a href={href} target="_blank" rel="noreferrer">
              <li className="hover:bg-primary dark:text-white">
                {icon}
                <span className="title">{title}</span>
              </li>
            </a>
          )}
          {link && (
            <Link to={link} onClick={toggleMenu}>
              <li
                className={`${
                  pathname?.includes(link) ? "active bg-primary" : ""
                } hover:bg-primary dark:text-white`}
              >
                {icon}
                <span className="title">{title}</span>
              </li>
            </Link>
          )}
        </>
      )}

      {children && isShowingSub && (
        <ul
          className={`${
            isShowingSub ? "show" : ""
          } sub-menu bg-primary bg-opacity-5`}
        >
          {children?.map((sub) => (
            <Link to={sub?.link} onClick={toggleMenu} key={sub?.title}>
              <li
                className={`${
                  pathname?.includes(sub?.link) ? "active bg-primary" : ""
                } hover:bg-primary dark:text-white`}
              >
                {sub?.title}
              </li>
            </Link>
          ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;
