import { logger } from "./general";

const errorHandler = (error = {}) => {
  let message = "";
  logger(error);
  if (error) {
    if (error?.status === 401 || error?.status === 403) {
      message =
        "You are either not autorized to access this resource or your session has expired. Please login again";
      localStorage.clear();
      localStorage.setItem("err", message);

      window.location.reload();
    } else if (Array.isArray(error?.data?.error)) {
      message = error?.data?.error?.join(", ");
    } else {
      message =
        error.data?.msg ||
        error?.data?.error ||
        error?.data?.error?.message ||
        "Something went wrong. Please try again";
    }
  } else {
    message = "Something went wrong, try again please.";
  }
  return message.toString();
};

export default errorHandler;
