import React, { useEffect } from "react";

import TransactionsContainer from "../../components/containers/wallet/Transactions";
import ViewWrapper from "../../layouts/ViewWrapper";

const Wallet = () => {
  useEffect(() => {}, []);

  return (
    <ViewWrapper header="Transactions">
      <TransactionsContainer />
    </ViewWrapper>
  );
};

export default Wallet;
