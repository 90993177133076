import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import Button from "../../inputs/Button";
import TextInput from "../../inputs/TextInput";
import Tick from "../../../assets/svg/green-bg-tick.svg";
import CartContext from "../../../contexts/CartContext";
import { formatCurrency } from "../../../utilities/general";
import notification from "../../../utilities/notification";
import handleFetch from "../../../api/handleFetch";
// import handleFetch from '../../../api/handleFetch';
// import Loading from '../../common/Loading';

const Invest = ({ data = {}, closeModal = () => {} }) => {
  const history = useHistory();
  const { cart, addToCart } = useContext(CartContext);
  const [form, setForm] = useState(1);
  const [amount, setAmount] = useState("");
  const [purchase, setPurchase] = useState({
    offer_id: data?.id,
    amount: "",
  });

  const handleBuyToken = async () => {
    await handleFetch({
      endpoint: "/offers/checkout",
      method: "POST",
      body: {
        amount: purchase.amount,
        offer_id: purchase.offer_id
      },
      auth: true,
    })
      .then((res) => {
        setForm(2);
        // clearCart();
        const user = JSON.parse(localStorage.getItem("u"));
        localStorage.setItem(
          "u",
          JSON.stringify({
            ...user,
            formatted_balance: formatCurrency(
              user?.wallet?.balance - purchase.amount
            ),
          })
        );
      })
      .catch((error) => {
        notification({
          title: "Error",
          message: error?.toString(),
          type: "danger",
        });
      });
    // setIsLoading(false);
  };

  const handleAddToCart = () => {
    const duplicate = cart?.filter((item) => item?.id === data?.id);
    if (duplicate?.length) {
      notification({
        message: `${data?.title?.toUpperCase()} already exist in your cart.`,
        type: "warning",
      });
      return;
    }
    addToCart({ ...data, amount });
    setForm(2);
  };

  const handleChange = (e) => {
    const val = e.target.value;
    // if (parseInt(val, 10) >= 5000) {
    // number is at least 125.

    if (
      val?.match(/^\d*$/) &&
      (parseInt(val, 10) <= data?.remaining_amount || val === "")
    ) {
      setPurchase({
        ...purchase,
        amount: Number(val),
      });
      setAmount(val);
    }
  };

  // checks for the minimum input after 1s
  useEffect(() => {
    const debouncer = setTimeout(() => {
      if (parseInt(purchase.amount, 10) < 5000) {
        notification({
          message: "Minimum is at least 5000",
          type: "warning",
        });
      }
    }, 1000);
    return () => {
      clearTimeout(debouncer);
    };
  }, [purchase.amount]);

  const handleDownload = (uri) => {
    const link = document.createElement("a");
    link.href = uri;
    window.open(uri, "_blank");
  };

  return (
    <>
      {/* {isLoading && <Loading />} */}
      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl font-bold mb-5">Invest</h2>

          <div className="w-full">
            <p className="text-gray-500 flex text-base font-bold border-b pb-3 mb-5">
              {data?.title}
              <a
                className="block ml-2 outline-none text-blue-700 cursor-pointer"
                href="https://moneyworx.ganbaru.xyz/"
                rel="noreferrer"
                target="_blank"
              >
                Visit website
              </a>
            </p>
            <div className="mb-10">
              <TextInput
                value={purchase.amount}
                onChange={handleChange}
                className="w-full mb-5"
                placeholder="Min 5000"
                title="Minimum of 5000"
                label="How much do you wish to invest (₦)?"
              />
              <div className="w-full flex justify-between items-center p-4 border-b">
                <p className="">Investment sums:</p>
                <p className="text-xl font-bold">
                  {formatCurrency(data?.max_amount)}
                </p>
              </div>
              <div className="w-full flex justify-between items-center p-4 border-b">
                <p className="">Available amount:</p>
                <p className="text-xl font-bold">
                  {formatCurrency(data?.remaining_amount)}
                </p>
              </div>
              <div className="w-full flex justify-between items-center p-4 border-b">
                <p className="">Interest rate:</p>
                <p className="text-xl font-bold text-green-500">{`${data?.interest_rate}% PA`}</p>
              </div>
              <div className="w-full flex justify-between items-center p-4 border-b">
                <p className="">Tenure:</p>
                <p className="text-xl font-bold">{`${data?.tenure} month(s)`}</p>
              </div>
              <div className="w-full flex justify-between items-center p-4 border-b">
                <p className="">Product paper:</p>

                <p className="text-xl font-bold flex items-center">
                  <span className="text-md pt-1">Download</span>
                  <FiDownload
                    fontSize="20px"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleDownload(data?.product_paper)}
                  />
                </p>
              </div>
            </div>

            <Button
              onClick={handleBuyToken}
              className="w-full mt-10"
              disabled={amount < 5000}
              paddingY="py-3"
            >
              Purchase investment
            </Button>
          </div>
        </div>
      )}

      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p>Purchase successful!</p>
              {/* <p className="text-gray-500">{`You have added ${data?.title} to cart`}</p> */}
            </div>

            {/* <div className="flex flex-wrap -mx-3 mt-5">
              <div className="px-3 w-1/2">
                <Button
                  onClick={closeModal}
                  paddingY="py-2.5"
                  className="w-full"
                  bgColor="bg-white"
                  textColor="text-primary"
                  border
                >
                  Continue
                </Button>
              </div>
              <div className="px-3 w-1/2">
                <Button
                  onClick={() => history.push("/home/cart")}
                  paddingY="py-2.5"
                  bgColor="bg-white"
                  textColor="text-primary"
                  border
                  className="w-full"
                >
                  Checkout
                </Button>
              </div>
            </div> */}
          </center>
        </div>
      )}
    </>
  );
};

export default Invest;
