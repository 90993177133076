import React from "react";

const Textarea = ({
  className,
  name,
  label,
  value,
  onChange,
  readOnly,
  disabled,
  onBlur,
  onKeyDown,
  onKeyPress,
  rows,
  maxLength,
  placeholder,
}) => (
  <div className={`${className || ""} form-group relative`}>
    <label htmlFor={name} className="text-sm block pb-1">
      {label}
    </label>
    <textarea
      name={name}
      id={name}
      rows={rows}
      value={value}
      className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 hover:border-alat-dark"
      onChange={onChange}
      readOnly={readOnly}
      onBlur={onBlur}
      disabled={disabled}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      maxLength={maxLength}
      autoComplete="off"
      placeholder={placeholder || ""}
    />
  </div>
);

export default Textarea;
