import React, { useState } from "react";

import Button from "../../inputs/Button";
import Textarea from "../../inputs/Textarea";
import TextInput from "../../inputs/TextInput";
import Tick from "../../../assets/svg/green-bg-tick.svg";

import Loading from "../../common/Loading";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";

const EditAspiration = ({ updateList, item, onClose }) => {
  const [form, setForm] = useState(1);
  const [title, setTitle] = useState(item.header);
  const [content, setContent] = useState(item.write_up);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    const body = { title, body: content, id: item.id };

    await handleFetch({
      endpoint: "/user/aspirations",
      method: "PUT",
      body,
    })
      .then((res) => {
        updateList(body);
        // updateList(res?.data);
        setForm(2);
      })
      .catch((err) => {
        notification({
          title: "Error",
          message: err?.toString(),
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}

      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl font-bold mb-5">Add Aspiration</h2>

          <div className="w-full">
            <div className="mb-10">
              <TextInput
                value={title}
                onChange={(e) => setTitle(e?.target?.value)}
                className="w-full mb-5"
                label="Title"
              />
              <Textarea
                value={content}
                rows={5}
                onChange={(e) => setContent(e?.target?.value)}
                className="w-full mb-5"
                label="Content"
              />
            </div>

            <Button
              className="w-full mt-10"
              onClick={handleSubmit}
              paddingY="py-3"
              disabled={isLoading || !(title && content)}
            >
              Submit
            </Button>
          </div>
        </div>
      )}

      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500">
                You have successfully added a new aspiration
              </p>
            </div>

            <Button onClick={onClose} className="w-full mt-5" paddingY="py-3">
              Okay
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default EditAspiration;
