import React, { useState } from "react";

import Modal from "react-responsive-modal";
import { BsBank } from "react-icons/bs";
import { RiBankLine } from "react-icons/ri";
import { FiPlus } from "react-icons/fi";

import Button from "../../inputs/Button";
import AddBank from "./AddBank";

const BankAccounts = ({ data }) => {
  const [showActions, setShowActions] = useState(false);
  const [addBank, setAddBank] = useState(false);

  return (
    <>
      <div className="w-full mb-10">
        <Button
          className={`mb-4 w-full ${showActions ? "bg-gray-200" : ""}`}
          onClick={() => setShowActions(!showActions)}
          bgColor="bg-white"
          textColor="text-gray-500 dark:text-black"
          paddingY="py-2"
          paddingX="px-5"
          border
          borderColor="border-gray-300"
        >
          <BsBank className="mr-6 w-8 h-auto" />
          Bank Accounts
        </Button>

        {showActions && (
          <div className="w-full mb-5">
            {data?.map((item) => (
              <div
                key={item.id}
                className="w-full flex justify-between items-center py-4 px-1 border-b last:border-0 hover:bg-gray-100 cursor-pointer dark:text-white dark:hover:text-black"
              >
                <div className="flex items-center">
                  <RiBankLine className="dark:text-black w-12 mr-4 h-auto p-3 bg-blue-200 rounded-full" />
                  <div className="">
                    <h3 className="text-lg  font-bold">{item?.account_name}</h3>
                    <p className="text-base text-gray-400">{`${item.bank.name} - ${item.account_number}`}</p>
                  </div>
                </div>
              </div>
            ))}

            <Button
              className="flex items-center mt-3"
              onClick={() => setAddBank(true)}
            >
              <FiPlus className="mr-3 text-lg" />
              Add Bank Account
            </Button>
          </div>
        )}
      </div>

      <Modal
        onClose={() => setAddBank(!addBank)}
        open={addBank}
        closeOnOverlayClick={false}
        classNames={{
          modal: "rounded-md py-3 w-full sm:w-96",
        }}
        center
      >
        <AddBank onClose={() => setAddBank(!addBank)} />
      </Modal>
    </>
  );
};

export default BankAccounts;
