import React, { useEffect } from "react";
import PortfolioContainer from "../components/containers/portfolio/Portfolio";

import ViewWrapper from "../layouts/ViewWrapper";

const Portfolio = () => {
  useEffect(() => {}, []);

  return (
    <ViewWrapper header="Portfolio">
      <PortfolioContainer />
    </ViewWrapper>
  );
};

export default Portfolio;
