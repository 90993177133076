import React from "react";

import { IoIosHome, IoIosPeople } from "react-icons/io";
import { RiPieChart2Fill, RiUserFill } from "react-icons/ri";
import { GiWallet, GiHumanTarget } from "react-icons/gi";

const sidebarMenu = () => {
  const monieworx = [
    {
      title: "Home",
      link: "/home",
      icon: <IoIosHome className="icon" />,
    },
    {
      title: "Portfolio",
      link: "/portfolio",
      icon: <RiPieChart2Fill className="icon" />,
    },
    // {
    //   title: "Community",
    //   href: "https://streetzweb.netlify.app/",
    //   icon: <IoIosPeople className="icon" />,
    // },
    {
      title: "Goals",
      link: "/goals",
      icon: <GiHumanTarget className="icon" />,
      children: [
        { title: "Investments Goals", link: "/goals/goals" },
        { title: "Aspirations", link: "/goals/aspirations" },
      ],
    },
    {
      title: "Wallet",
      link: "/wallet",
      icon: <GiWallet className="icon" />,
      children: [
        { title: "Items", link: "/wallet/items" },
        { title: "Transactions", link: "/wallet/transactions" },
      ],
    },
    {
      title: "Account",
      link: "/account",
      icon: <RiUserFill className="icon" />,
    },
  ];

  return monieworx;
};

export default sidebarMenu;
