import React, { useState } from "react";

import Button from "../../inputs/Button";
import TextInput from "../../inputs/TextInput";
import Tick from "../../../assets/svg/green-bg-tick.svg";
import handleFetch from "../../../api/handleFetch";
import notification from "../../../utilities/notification";
import Loading from "../../common/Loading";

const SetNIN = ({ closeModal = () => {} }) => {
  const [form, setForm] = useState(1);
  const [nin, setNin] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const val = e.target.value;

    if (val?.match(/^\d*$/)) {
      setNin(val);
    }
  };

  const handleSetBvn = async () => {
    setIsLoading(true);
    await handleFetch({
      endpoint: "/user/set-nin",
      method: "POST",
      body: { nin },
      auth: true,
    })
      .then((res) => {
        if (res.data.status) {
          setForm(2);
          const user = JSON.parse(localStorage.getItem("u"));
          localStorage.setItem("u", JSON.stringify({ ...user, nin }));
        }
        notification({
          title: "Error",
          message:
            res.msg?.toString() ||
            "An error occurred while trying to set NIN. Please, try again",
          type: "danger",
        });
      })
      .catch((error) => {
        notification({
          title: "Error",
          message:
            error?.toString() ||
            "An error occurred while trying to set NIN. Please, try again",
          type: "danger",
        });
      });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      {form === 1 && (
        <div className="w-full">
          <h2 className="text-xl font-bold mb-5">Set Your NIN</h2>

          <div className="w-full">
            <div className="mb-10">
              <TextInput
                value={nin}
                onChange={handleChange}
                className="w-full mb-5"
                label="National Identification Number (NIN)"
              />
            </div>

            <Button
              onClick={handleSetBvn}
              className="w-full mt-10"
              disabled={!nin}
              paddingY="py-3"
            >
              Submit NIN
            </Button>
          </div>
        </div>
      )}

      {form === 2 && (
        <div className="w-full">
          <center className="w-full px-10 mt-5">
            <div className="mb-5">
              <img src={Tick} className="w-24 h-24 mb-5" alt="Success" />
              <p className="text-gray-500">
                You have successfully setup your NIN
              </p>
            </div>

            <Button
              onClick={() => window.location.reload()}
              className="w-full mt-5"
              paddingY="py-3"
            >
              Okay
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default SetNIN;
