import React from "react";

import { HiArrowUp } from "react-icons/hi";

import { formatDate } from "../../../utilities/dateTime";

const Card = ({ data = {} }) => (
  <>
    <div
      // style={{ backgroundColor: "#F0F0F0" }}
      className="w-full rounded-lg bg-[#F0F0F0] dark:bg-secondary px-5 py-7 relative overflow-hidden"
    >
      <div className="mb-14">
        <p className="text-red-600 font-bold dark:text-yellow text-lg mb-3">
          Portfolio Value
        </p>
        <div className="flex items-center">
          <h2 className="text-4xl dark:text-white text-black font-bold">
            {data?.total_portfolio_value}
          </h2>
          <span
            // style={{ color: "#0097A7" }}
            className="ml-6 text-[#0097A7] dark:text-[#8CD867] font-bold text-xl flex items-start"
          >
            <HiArrowUp className="mr-0.5" />
            {`${data?.percent_profit || 0}%`}
          </span>
        </div>
      </div>
      <div className="w-full flex justify-between">
        <div className="text-black">
          <h2 className="text-2xl dark:text-white font-bold">
            {data?.total_invested}
          </h2>
          <p
            className="mb-1 text-[#0097A7] font-bold dark:text-[#8CD867]"
            // style={{ color: "#0097A7" }}
          >
            Payments recieved
          </p>
        </div>
        {/* <div className="text-white text-right ml-3">
          <p className="text-gray-200 mb-1">Next Expected Amount</p>
          <h2 className="text-2xl font-bold">{data?.next_pay_amount}</h2>
        </div> */}
      </div>
    </div>
    {/* <div className="flex justify-between items-center text-gray-400 mt-3">
      <h3 className="text-lg font-bold">NEXT PAY DAY</h3>
      <p className="">{formatDate(data?.next_pay_day)}</p>
    </div> */}
  </>
);

export default Card;
