import React, { useEffect } from "react";

import ViewWrapper from "../../layouts/ViewWrapper";
import CartContainer from "../../components/containers/dashboard/Cart";

const Cart = () => {
  useEffect(() => {}, []);

  return (
    <ViewWrapper header="Cart">
      <CartContainer />
    </ViewWrapper>
  );
};

export default Cart;
